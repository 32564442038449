var freeingSpacesFromCSV = [
 {
   "Name": "Zapatistas (EZLN)",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "",
   "Latitude 1": "17.342785394334100",
   "Longitute 1": "-92.754202163673300",
   "Latitude 2": "17.452849644589000",
   "Longitute 2": "-92.666123642205000",
   "Latitude 3": "17.492856556614700",
   "Longitute 3": "-92.594821981968800",
   "Latitude 4": "17.571844332245900",
   "Longitute 4": "-92.519326106424500",
   "Latitude 5": "17.619820087221200",
   "Longitute 5": "-92.352606046913100",
   "Latitude 6": "17.698752385231600",
   "Longitute 6": "-92.348411831605100",
   "Latitude 7": "17.688762869701900",
   "Longitute 7": "-92.272915956060800",
   "Latitude 8": "17.773656017854300",
   "Longitute 8": "-92.195322972862600",
   "Latitude 9": "17.702748036077500",
   "Longitute 9": "-92.066350850541900",
   "Latitude 10": "17.846532088688100",
   "Longitute 10": "-91.926893191550400",
   "Latitude 11": "17.824572572885000",
   "Longitute 11": "-91.792678301693900",
   "Latitude 12": "17.735708758409700",
   "Longitute 12": "-91.829377685639100",
   "Latitude 13": "17.568321200726900",
   "Longitute 13": "-91.900837215441600",
   "Latitude 14": "16.600463141649300",
   "Longitute 14": "-90.658286550764900",
   "Latitude 15": "16.089080428793800",
   "Longitute 15": "-90.958406010548700",
   "Latitude 16": "16.072909806027600",
   "Longitute 16": "-91.732545925505200",
   "Latitude 17": "15.735724514747200",
   "Longitute 17": "-91.928885762739100",
   "Latitude 18": "16.199375944163500",
   "Longitute 18": "-92.996709820142800",
   "Latitude 19": "16.728531874198800",
   "Longitute 19": "-92.558113715815800",
   "Latitude 20": "17.057949522272400",
   "Longitute 20": "-93.062735040149100"
 },
 {
   "Name": "The Foundry",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://bravosfoundry.com",
   "Description": "Non-profit and self-organized project space / residency",
   "Latitude 1": "43.601181745652400",
   "Longitute 1": "-7.641724521729830",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Borda",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.laborda.coop/en/",
   "Description": "Collectively owned cooperative housing project",
   "Latitude 1": "41.369601042553900",
   "Longitute 1": "2.134867347748790",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Autonome Wohnfabrik",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://autonome-wohnfabrik.at/",
   "Description": "Collectively owned self-organized housing project",
   "Latitude 1": "52.364415694068700",
   "Longitute 1": "4.937410365508580",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Willy Fred",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.willy-fred.org/",
   "Description": "Collectively owned self-organized housing project",
   "Latitude 1": "48.306188794281900",
   "Longitute 1": "14.290021074883300",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Regenbogenfabrik",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.regenbogenfabrik.de/",
   "Description": "Former squat turned project space / hostel (solidary economy, direct democracy)",
   "Latitude 1": "52.496342866092300",
   "Longitute 1": "13.426970900007400",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "ZAD NDDL",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://zad.nadir.org/",
   "Description": "A zone to defend",
   "Latitude 1": "47.347792100331900",
   "Longitute 1": "1.732647970502410",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Errekaleor ",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://errekaleorbizirik.org/index.php/eu/",
   "Description": "The Basque Country's largest squat",
   "Latitude 1": "42.835529414049600",
   "Longitute 1": "2.650654755229440",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Milwaukee Autonomous Tenants Union",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.matunion.org",
   "Description": "Dedicated dual power, direct action, rank & file tenants union",
   "Latitude 1": "43.038902000000000",
   "Longitute 1": "-87.906471000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "I.W.W. Worker Owned Cooperative Shop",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://mkegmb.org",
   "Description": "Buidling worker power, workplace democracy and worker-owned cooperative shops",
   "Latitude 1": "43.038902000000000",
   "Longitute 1": "-87.906471000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Cooperation Milwaukee",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://milwaukee.dsawi.org/get-involved/solidarity-mutual-aid/",
   "Description": "Seeding solidarity economy and mutual aid initiaves in occupied Sauk, Potawatomi, Ojibwe. Algonquian, and Menominee land",
   "Latitude 1": "43.038902000000000",
   "Longitute 1": "-87.906471000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Milwaukee Cohousing & Cooperatives",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.milwareacohousing.org",
   "Description": "Creating housing cooperatives in Milwaukee area, battling housing inequity",
   "Latitude 1": "43.038902000000000",
   "Longitute 1": "-87.906471000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Red Emmas",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://redemmas.org",
   "Description": "Collective owned and operated coffeeshop & bookstore.",
   "Latitude 1": "39.304270848561800",
   "Longitute 1": "-76.618515206786600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "2640 Space",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.2640space.net/",
   "Description": "A noncommercial, collectively-managed space for radical politics & grassroots culture.",
   "Latitude 1": "39.320468749875200",
   "Longitute 1": "-76.615662197909000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "AK Press",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.akpress.org/",
   "Description": "Worker-managed, independent publisher and book distributor that specialises in radical left and anarchist literature. Operated out of Chico, California, the company is collectively owned.",
   "Latitude 1": "39.806840000000000",
   "Longitute 1": "-121.854190000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Maison Rose",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "lamaisonrosegruber.org",
   "Description": "Free space with workshops",
   "Latitude 1": "48.578564952993100",
   "Longitute 1": "7.709979298105600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Le Wagon Souk",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.facebook.com/LeWagonSouk/",
   "Description": "Free shop with plants and other stuff, Food kitchen for precarious people",
   "Latitude 1": "48.578282820618800",
   "Longitute 1": "7.709697700000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "L'Hôtel de la Rue",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://larouetournestrasbourg.fr/",
   "Description": "Housing for the homeless, over 160 refugees living there",
   "Latitude 1": "48.578891486047300",
   "Longitute 1": "7.709893469316030",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Maison Mimir",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://azqs.com/maison-mimir/",
   "Description": "Housing for the homeless and social / cultural events ",
   "Latitude 1": "48.583351128541500",
   "Longitute 1": "7.759334869316030",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "OCCII",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://occii.org/",
   "Description": "Cultural space",
   "Latitude 1": "52.354819842672800",
   "Longitute 1": "4.855526892051890",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Joe's Garage",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "joesgarage.nl",
   "Description": "Autonomous space : solidarity and cultural events",
   "Latitude 1": "52.355953862549700",
   "Longitute 1": "4.925075930683960",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Vrankrijk",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "vrankrijk.nl",
   "Description": "Historical cultural centre, originally squatted",
   "Latitude 1": "52.371835213777800",
   "Longitute 1": "4.889229130683960",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Editions Libertalia",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "libertalia.fr",
   "Description": "Anarchist editor in Montreuil (Paris suburbs)",
   "Latitude 1": "48.854036298526500",
   "Longitute 1": "2.392036827787080",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Editions du commun",
   "Type": "",
   "Last Confirmed Date": "1/23/2022",
   "Website": "editionsducommun.fr",
   "Description": "Editor in Rennes with Opensource books online",
   "Latitude 1": "48.088941064917700",
   "Longitute 1": "-1.651697023290080",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Soweto",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://soweto.nl/en/about/projects",
   "Description": "Social housing cooperative",
   "Latitude 1": "52.364402590358100",
   "Longitute 1": "4.937453280438850",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Sostre Civic",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://sostrecivic.coop/",
   "Description": "Tenant ownership / cooperative housing organization",
   "Latitude 1": "41.392564418891100",
   "Longitute 1": "2.174009899997200",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Ecodorp Boekel",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.ecodorpboekel.nl/",
   "Description": "Eco-village in the Netherlands; member of VrijCoop",
   "Latitude 1": "51.595760872992200",
   "Longitute 1": "5.680674131342630",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bajesdorp",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://bajesdorp.nl/",
   "Description": "Cooperative housing / artist studio project in a former prison area; member of VrijCoop",
   "Latitude 1": "52.336133681163300",
   "Longitute 1": "4.927345203987230",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "De Groene Gemeenschap",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://degroenegemeenschap.org/",
   "Description": "Cooperative housing project; member of VrijCoop, Soweto and Mietshäuser Syndikat",
   "Latitude 1": "52.364544724983300",
   "Longitute 1": "4.988028115881650",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rijkshemelvaartdienst",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.rijkshemelvaart.com/",
   "Description": "Former squat, now space for artists to work and live; member of VrijCoop",
   "Latitude 1": "52.334742616873500",
   "Longitute 1": "4.806939869054290",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Pension Almonde",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.pension-almonde.nl",
   "Description": "Temporary home for urban nomads in an area planned to be demolished; member of Stad in de Maak",
   "Latitude 1": "51.928927776168900",
   "Longitute 1": "4.481239206550780",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Banierstraat 62",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.stadindemaak.nl/banierstraat-62/",
   "Description": "Several artist studios in a former marijuana plantation; member of Stad in de Maak",
   "Latitude 1": "51.930871285416300",
   "Longitute 1": "4.472396993561460",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bloklandstraat 190",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.stadindemaak.nl/bloklandstraat-190/",
   "Description": "Housing / workspace; member of Stad in de Maak",
   "Latitude 1": "51.937102208317800",
   "Longitute 1": "4.478445076690650",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Pieter de Raadtstraat 35 & 37",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.stadindemaak.nl/pieter-de-raadtstraat-35-37/",
   "Description": "Housing / workspace; member of Stad in de Maak",
   "Latitude 1": "51.928947559868800",
   "Longitute 1": "4.468970164010400",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Zwaanshals 288B",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.stadindemaak.nl/zwaanshals-288-b/",
   "Description": "Housing / workspace; member of Stad in de Maak",
   "Latitude 1": "51.933358617107000",
   "Longitute 1": "4.486100363355460",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Nieuwland",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://nieuwland.cc/",
   "Description": "Housing / project space",
   "Latitude 1": "52.362402089807100",
   "Longitute 1": "4.929990179281170",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Porcheritz",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://clip.ouvaton.org/les-projets/",
   "Description": "Housing freed from the market; member of le Clip",
   "Latitude 1": "48.865642425068200",
   "Longitute 1": "2.406255346955150",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Déviation",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.ladeviation.org/",
   "Description": "Space for artistic research; member of le Clip",
   "Latitude 1": "43.368587779289700",
   "Longitute 1": "5.306144439693700",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Køpi",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://koepi137.net/welcome.html",
   "Description": "One of the last remaining original squats in Berlin",
   "Latitude 1": "52.508070049213600",
   "Longitute 1": "13.426335030683900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rigaer 94",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://rigaer94.squat.net/",
   "Description": "One of the last remaining original squats in Berlin",
   "Latitude 1": "52.517956809985400",
   "Longitute 1": "13.458004040392200",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Manifesten",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.facebook.com/manifesten/",
   "Description": "Project space; member of le Clip",
   "Latitude 1": "43.297377082035900",
   "Longitute 1": "5.385610531437690",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Woodbine",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.woodbine.nyc/",
   "Description": "Woodbine is a volunteer-run experimental hub in Ridgewood, Queens for developing the practices, skills, and tools needed to build autonomy.",
   "Latitude 1": "40.706597542060100",
   "Longitute 1": "-73.908096187042800",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Performing Arts Forum",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.pa-f.net",
   "Description": "PAF is a residency and a user-created, user-innovative informal institution for arts and sciences",
   "Latitude 1": "49.5135734",
   "Longitute 1": "3.835899181",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bidston Observatory",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.bidstonobservatory.org/",
   "Description": "Artistic research center and residency",
   "Latitude 1": "53.40081088",
   "Longitute 1": "-3.074224402",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kerminy",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://kerminy.org/",
   "Description": "Independent meeting place for artistic, research, theoretical and cultural production residencies; agricultural focus.",
   "Latitude 1": "47.984951607481000",
   "Longitute 1": "-3.833909963744380",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "KRA - Kravín Rural Arts",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://kra.land/",
   "Description": "Artist residency with a focus on artistic-ecological research.",
   "Latitude 1": "50.077732687188200",
   "Longitute 1": "14.414986255634600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Fritz Manor",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.fritzmanor.com/",
   "Description": "Open residency for artists, writers, musicians, dancers, thinkers and the like.",
   "Latitude 1": "53.711298728030900",
   "Longitute 1": "12.827535725100200",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "MASSIA",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.massia.ee",
   "Description": "MASSIA is a user-created informal residency space initiated and run by artists, theoreticians and practitioners.",
   "Latitude 1": "57.998781225135900",
   "Longitute 1": "24.588549515341900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Konvent",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.konventzero.com",
   "Description": "Konvent is a creative space that breathes life into disused industrial heritage.",
   "Latitude 1": "42.075593181812100",
   "Longitute 1": "1.870176223290080",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Calafou",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://calafou.org",
   "Description": "Eco-industrial post-capitalist colony (self-organized and collectively owned)",
   "Latitude 1": "41.511107696611000",
   "Longitute 1": "1.703341926478310",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rimaflow",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://rimaflow.it/",
   "Description": "Self-organized workshops in an occupied car factory.",
   "Latitude 1": "45.428931085354500",
   "Longitute 1": "9.048748684658010",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Tolko Sami",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.instagram.com/tolko_sami/",
   "Description": "Self-organized collectively managed cultural center",
   "Latitude 1": "55.749658000000000",
   "Longitute 1": "37.581451000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "AK83",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "ak83.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.452600000000000",
   "Longitute 1": "11.045030000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Betriebsküche",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.kuneterakete.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.056571800000000",
   "Longitute 1": "13.711741700000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "RM16",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://rm16.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.080210000000000",
   "Longitute 1": "13.721010000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Koko3",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "koko3.home.blog",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.075570000000000",
   "Longitute 1": "13.724460000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Mangelwirtschaft",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "mangelwirtschaft.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.074240000000000",
   "Longitute 1": "13.693920000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "2n40",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.013150000000000",
   "Longitute 1": "13.769610000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Weinberg21",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.weinberg21.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.003780000000000",
   "Longitute 1": "13.897320000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "HC9",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.hc9.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.985070000000000",
   "Longitute 1": "13.578680000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Grünes Örtchen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.147176500000000",
   "Longitute 1": "14.991549300000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Harte9",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.151388400000000",
   "Longitute 1": "14.981419700000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Zelle79",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.zelle79.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.750147800000000",
   "Longitute 1": "14.339778800000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "kunterbunte 19",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.339290000000000",
   "Longitute 1": "12.326480000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Spieszgesellen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.339400000000000",
   "Longitute 1": "12.326360000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "WuK",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.339040000000000",
   "Longitute 1": "12.329060000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Conserve",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.leconserve.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.334192000000000",
   "Longitute 1": "12.320701000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "SOL Leipzig (Solidarisch Organisiert Leben)",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.sol-leipzig.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.350150000000000",
   "Longitute 1": "12.309050000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "P9",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.313050000000000",
   "Longitute 1": "12.323720000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "",
   "Type": "",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "",
   "Longitute 1": "",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Zolle 11",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.zollschuppenverein.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.325980000000000",
   "Longitute 1": "12.324500000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Naumburger 40",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.326950000000000",
   "Longitute 1": "12.326450000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Klinge 10",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.klinge10.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.326875300000000",
   "Longitute 1": "12.324118600000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Wurze",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://wurze.de/",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.338530000000000",
   "Longitute 1": "12.407260000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "KlausHaus",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "klaushaus.info",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.338330000000000",
   "Longitute 1": "12.407160000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Schlicht & Ergreifend",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "schlichtergreifend.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.345260000000000",
   "Longitute 1": "12.413900000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "SchönerHausen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "schoenerhausen.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.344800000000000",
   "Longitute 1": "12.423600000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Gleiserei",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.344819500000000",
   "Longitute 1": "12.416090900000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Die Lobelei",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.lobelei.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.133346000000000",
   "Longitute 1": "12.445252000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Reil 76",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.507187100000000",
   "Longitute 1": "11.965453100000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bahnhof Eisenberg",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.971240000000000",
   "Longitute 1": "11.892810000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Egge",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.813520000000000",
   "Longitute 1": "12.531800000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Gata Negra",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "sistr26.blogsport.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.492580000000000",
   "Longitute 1": "12.123010000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Das Kompott",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.839410000000000",
   "Longitute 1": "12.905970000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kooperative Schloss Gersdorf",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.schloss-gersdorf.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.059800000000000",
   "Longitute 1": "13.217550000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Linie206",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.528880000000000",
   "Longitute 1": "13.402960000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bödi 9",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.499150000000000",
   "Longitute 1": "13.462780000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Grüni",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://gruenberger.so36.net",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.511430000000000",
   "Longitute 1": "13.459640000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Seume14",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.seume14.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.510310000000000",
   "Longitute 1": "13.462670000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rigaer78",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.rigaer78.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.516640000000000",
   "Longitute 1": "13.464200000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Scharni38",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.512750000000000",
   "Longitute 1": "13.464130000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "LaVidaVerde",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.lavidaver.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.509190000000000",
   "Longitute 1": "13.494200000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Türrschmidt30",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.türr30.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.503450000000000",
   "Longitute 1": "13.482080000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "WilMa19",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://wilma19.de/",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.513680000000000",
   "Longitute 1": "13.488300000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kastanie",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.537550000000000",
   "Longitute 1": "13.408920000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hausprojekt M29",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "hausprojekt-m29.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.539560000000000",
   "Longitute 1": "13.419500000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kumi*13",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.kumi13.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.499303600000000",
   "Longitute 1": "13.366766300000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Zossener 48",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.zossener48.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.493570000000000",
   "Longitute 1": "13.395530000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "O 45",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.502840000000000",
   "Longitute 1": "13.414060000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "RaumPlusX",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.499890000000000",
   "Longitute 1": "13.427130000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Reiche 114",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.494900000000000",
   "Longitute 1": "13.434690000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Mariendorfer Weg 74",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.463470000000000",
   "Longitute 1": "13.423970000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rathaussterne",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.482725952406700",
   "Longitute 1": "13.427131098247200",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Chuzpe Plietsch",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.463840000000000",
   "Longitute 1": "13.443710000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hausprojekt Burge",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "burge.li",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.457520000000000",
   "Longitute 1": "13.380080000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hausprojekt Rahnsdorf",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.hausprojekt-berlinrahnsdorf.net",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.435170000000000",
   "Longitute 1": "13.702840000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "jetty",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.638445000000000",
   "Longitute 1": "13.492729000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "PinkePanke",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.564130000000000",
   "Longitute 1": "13.384540000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Jagow 15",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.547450000000000",
   "Longitute 1": "13.204790000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Eichelkämper_innen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.436740000000000",
   "Longitute 1": "13.035370000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Zeppi 29",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "zeppi29.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.394130000000000",
   "Longitute 1": "13.038140000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Projekthaus Potsdam",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.projekthaus-potsdam.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.395290000000000",
   "Longitute 1": "13.120130000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Wohn- und Kulturprojekt U24",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.fu24.net",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.395650000000000",
   "Longitute 1": "13.107010000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Tuchmacher*innen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "tuchmacher.pilotton.com",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.395690000000000",
   "Longitute 1": "13.092100000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Wollestraße",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.397551700000000",
   "Longitute 1": "13.088482400000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "WohnMichel",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.wohnmichel.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.314630000000000",
   "Longitute 1": "13.017900000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "miteinander wohnen saarmund",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.319140000000000",
   "Longitute 1": "13.127960000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "wurzeln & wirken",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://wurzelnundwirken.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.548120000000000",
   "Longitute 1": "12.947360000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Vielseitenhof",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.vielseitenhof.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.171370000000000",
   "Longitute 1": "12.732300000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Alte Mühle Gömnigk",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.alte-muehle.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.172460000000000",
   "Longitute 1": "12.736980000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "doma",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.578800000000000",
   "Longitute 1": "13.881700000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "allerhand",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.allerhand-projekt.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.572990000000000",
   "Longitute 1": "13.878100000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Wohnprojekt Schöneiche",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "wohnprojekt-schoeneiche.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.461180000000000",
   "Longitute 1": "13.698960000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Soziales Zentrum „JWP-MittenDrin”",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.jwp-mittendrin.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.927550000000000",
   "Longitute 1": "12.794810000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Landpartie",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.319140000000000",
   "Longitute 1": "14.015770000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Gutshaus Grauenhagen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://gutshaus-grauenhagen.de/",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.407610000000000",
   "Longitute 1": "13.536850000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "STRAZE",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "54.100397399474900",
   "Longitute 1": "13.379024955979100",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Vereinsstraße",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "vereinsstrasse.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.565690000000000",
   "Longitute 1": "9.962410000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Projekt Eschenhof",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "projekt-eschenhof.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.471660000000000",
   "Longitute 1": "10.232540000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "GoMokry*",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "gomokry.blackblogs.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.518339800000000",
   "Longitute 1": "9.983800200000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Fährstraße 115",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "115bleibt.blackblogs.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.518373100000000",
   "Longitute 1": "9.980074900000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Raeume",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.raeume.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.213030000000000",
   "Longitute 1": "10.368810000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "FLUSE",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://fluse.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.254185500000000",
   "Longitute 1": "10.425972400000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Arnoldstraße 16",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.hueteundkostueme.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.549640000000000",
   "Longitute 1": "9.929100000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Handwerkerhof Ottensen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.handwerkerhof-ottensen.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.558840000000000",
   "Longitute 1": "9.927120000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Omaba",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.552490000000000",
   "Longitute 1": "9.929410000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Turm",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.553750000000000",
   "Longitute 1": "9.926890000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Inter-Pares",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.555390000000000",
   "Longitute 1": "9.946600000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kollektivbetrieb schickSAAL*",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "schicksaal.net",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.870250000000000",
   "Longitute 1": "10.680840000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Freie Hütte",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://freiehuette.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.852110000000000",
   "Longitute 1": "10.695100000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Analog 6.8",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.analog-68.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "54.136970000000000",
   "Longitute 1": "10.612660000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "frische bauern",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "frischebauern.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "54.089030000000000",
   "Longitute 1": "9.071640000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "G18",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.073920000000000",
   "Longitute 1": "8.798830000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Soziales Zentrum",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.063650000000000",
   "Longitute 1": "8.808450000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Mosaik",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.053650000000000",
   "Longitute 1": "8.820940000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Freies Haus 3d",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.073970000000000",
   "Longitute 1": "8.827900000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "eins_a",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "53.070270000000000",
   "Longitute 1": "8.838230000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Stadtteilleben",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.stadtteilleben.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.373670000000000",
   "Longitute 1": "9.703880000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Solidarischer Horst",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.solidarischer-horst.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.354660000000000",
   "Longitute 1": "9.672420000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Walke",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.freiraum-hameln.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.099050000000000",
   "Longitute 1": "9.363250000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "f.l.o.s.s.e.",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "wohnprojekt-flosse.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.992612700000000",
   "Longitute 1": "8.541448300000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "agathe",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.agathe-kassel.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.328830000000000",
   "Longitute 1": "9.496600000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hausprojekt Am Grün",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.806490000000000",
   "Longitute 1": "8.772000000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bettenhaus",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "bettenhausmarburg.noblogs.org/",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.816870000000000",
   "Longitute 1": "8.773190000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Ketzerbach",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.813760000000000",
   "Longitute 1": "8.765380000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Sonnendeck",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.798830000000000",
   "Longitute 1": "8.764050000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "die teichwiesel",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.796930000000000",
   "Longitute 1": "8.759400000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Unikat44",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www,unikat44.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.806090000000000",
   "Longitute 1": "8.762630000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kauz",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.837160000000000",
   "Longitute 1": "8.761870000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "ProWo",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://prowo.haus/",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.588910000000000",
   "Longitute 1": "8.703110000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "OM10",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://omzehn.noblogs.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.536091000000000",
   "Longitute 1": "9.931226000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Grünes Haus anner Ecke",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://wiese28.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.529121000000000",
   "Longitute 1": "9.927837000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Mensch*Meierei",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.mensch-meierei.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.340293000000000",
   "Longitute 1": "9.884016000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "VEB Wohnfabrik",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.690010000000000",
   "Longitute 1": "11.038890000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Thiembuktu",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.thiembuktu.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.106120000000000",
   "Longitute 1": "11.638340000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Uncrowd",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.uncrowd-home.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.130920000000000",
   "Longitute 1": "11.580870000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "LÜDIA",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "52.006910000000000",
   "Longitute 1": "11.324850000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "WIGWAM",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.muenster.org/wigwam",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.955570000000000",
   "Longitute 1": "7.629494000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Grafschaft 31",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://grafschaft31.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "51.919050000000000",
   "Longitute 1": "7.609690000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "DreiLessiDrei",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.955250000000000",
   "Longitute 1": "6.914710000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Die Neuerburg",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "dieneuerburg.wordpress.com/",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.937697800000000",
   "Longitute 1": "7.005113200000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Wohnprojekt Wohnsinn",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.wohnsinn-aachen.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.770400000000000",
   "Longitute 1": "6.079170000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "lila_bunt – Feministische Bildung, Praxis & Utopie (Tagungs- und Bildungshaus)",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.lila-bunt-zuelpich.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.672060000000000",
   "Longitute 1": "6.669593000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hausprojekt NiKa",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.nika.haus",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.109240000000000",
   "Longitute 1": "8.663520000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Fritze",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.123570000000000",
   "Longitute 1": "8.636150000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Assenland",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.assenland.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.125970000000000",
   "Longitute 1": "8.614050000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kollektiv Leben – Kolle",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "kolle-frankfurt.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.100334167480500",
   "Longitute 1": "8.549902915954590",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Schwarze 79",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.130190000000000",
   "Longitute 1": "8.897680000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Altes Neuwirtshaus",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://anwh.blogsport.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.108680000000000",
   "Longitute 1": "8.979850000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Heinersyndikat",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "heinersyndikat.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.847450000000000",
   "Longitute 1": "8.642550000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Distel",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.257090000000000",
   "Longitute 1": "8.198642000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Knotenpunkt",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.150300000000000",
   "Longitute 1": "8.535120000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Roter Backstein",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.183568900000000",
   "Longitute 1": "7.039538700000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "SWK Mannheim",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://swk-mannheim.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.502620000000000",
   "Longitute 1": "8.491650000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "13hafreiheit",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.13hafreiheit.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.502360000000000",
   "Longitute 1": "8.491100000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "umBAU² Turley",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.umbau-turley.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.502860000000000",
   "Longitute 1": "8.492020000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Esperanza",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "esperanza-mannheim.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.502190000000000",
   "Longitute 1": "8.490780000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Collegium Academicum",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.collegiumacademicum.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.409490000000000",
   "Longitute 1": "8.702990000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "SoKo OBG",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.kollektiv-obg.de/",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.411580000000000",
   "Longitute 1": "8.710980000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hagebutze",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.hagebutze.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.389120000000000",
   "Longitute 1": "8.682340000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Raumkante",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.raumkante.info",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.388995600000000",
   "Longitute 1": "8.682280500000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Templerhaus",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.templerhaus.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.546590000000000",
   "Longitute 1": "8.673700000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kesselhof",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "der-kesselhof.de/",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.779350000000000",
   "Longitute 1": "9.119900000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Linkes Zentrum Lilo Herrmann",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.linkeszentrumstuttgart.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.761760000000000",
   "Longitute 1": "9.156800000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "a-w-s",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.784820000000000",
   "Longitute 1": "9.192310000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Münze 13",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.muenze13.wordpress.com",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.519950000000000",
   "Longitute 1": "9.055360000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bläsikelter",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "blaesikelter.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.513698800000000",
   "Longitute 1": "9.053922600000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "4-Häuser-Projekt",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.vier-haeuser-projekt.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.511240000000000",
   "Longitute 1": "9.059980000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hegel 7",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.hegelstrasse7.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.514490000000000",
   "Longitute 1": "9.054530000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Lu15",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.lu15.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.513810000000000",
   "Longitute 1": "9.065830000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Wohnprojekt Schellingstraße",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.schellingstrasse.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.513720000000000",
   "Longitute 1": "9.054000000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Tante Huber",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.huberstrasse.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.509980000000000",
   "Longitute 1": "9.058250000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Leibnizhaus 2",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.leibnizhaus.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.521630000000000",
   "Longitute 1": "9.066600000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Alte Sprudelfabrik Eyach",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "wohnprojekt-eyach.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.446490000000000",
   "Longitute 1": "8.780510000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Soziales Zentrum Käthe und W-47",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.sz-kaethe.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.139010000000000",
   "Longitute 1": "9.226560000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Trauben & Rosinen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.trauben-rosinen.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.116322000000000",
   "Longitute 1": "9.736111000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Mittlere Mühle Tengen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://mittlere-muehle-tengen.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.807100000000000",
   "Longitute 1": "8.661760000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Paradies Wohn-GmbH – ein Frauenwohnprojekt",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.paradieswohn-gmbh.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.663400000000000",
   "Longitute 1": "9.161300000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Grether Ost",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.grether.syndikat.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.993060000000000",
   "Longitute 1": "7.840510000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Grether Süd",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.grether.syndikat.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.992970000000000",
   "Longitute 1": "7.839870000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Grether West",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.grether.syndikat.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.993220000000000",
   "Longitute 1": "7.840060000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Grün8",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.gruen8.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.993500000000000",
   "Longitute 1": "7.841990000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "JB9",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.998150000000000",
   "Longitute 1": "7.856310000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "WOGE und ARCHE im Sonnenhof",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.archeimsonnenhof.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.977420000000000",
   "Longitute 1": "7.823600000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "SUSI",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.susi-projekt.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.974430000000000",
   "Longitute 1": "7.827350000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Loretta",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.loretta-freiburg.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.972740000000000",
   "Longitute 1": "7.840040000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Freiau99",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "freiau99.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.991460000000000",
   "Longitute 1": "7.837540000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Höhe3",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.hoehedrei.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.024410000000000",
   "Longitute 1": "7.864120000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rennerstraße",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.996160000000000",
   "Longitute 1": "7.835350000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Guntram 38",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.998742000000000",
   "Longitute 1": "7.837985000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Ölmühle",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.oelmuehle-freiburg.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.974620000000000",
   "Longitute 1": "7.785150000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "HaWei21",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.hawei21.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.991200000000000",
   "Longitute 1": "7.813470000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Collage",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.994030000000000",
   "Longitute 1": "7.828420000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "mark20",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.mark20.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.992450000000000",
   "Longitute 1": "7.817040000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Luftschloss",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://3haeuserprojekt.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.987188000000000",
   "Longitute 1": "7.830998000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "LAMA",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.lamakat.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.987463000000000",
   "Longitute 1": "7.829963000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "schwereLos",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://3haeuserprojekt.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.988393000000000",
   "Longitute 1": "7.829776000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Villa Nostra",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.villanostra.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.920310000000000",
   "Longitute 1": "7.699940000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Allmende Gundelfingen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.047112000000000",
   "Longitute 1": "7.863203000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "AWI",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.967147232766600",
   "Longitute 1": "7.951304769248690",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "einzweidreisam",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.einzweidreisam.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.962921000000000",
   "Longitute 1": "7.949339000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kommunita Lotta",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.kommunita-lotta.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.963280000000000",
   "Longitute 1": "7.824530000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "KRONE",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.843780000000000",
   "Longitute 1": "7.703570000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bergfritzenhof",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.fritzinnen.de/",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.174880000000000",
   "Longitute 1": "7.871740000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Luzernenhof",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.luzernenhof.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "47.858410000000000",
   "Longitute 1": "7.642220000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Ligsalz8",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.ligsalz8.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.136280000000000",
   "Longitute 1": "11.542220000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Haussyndikat in der Oberbayerischen Provinz (HOP)",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://haussyndikat.rosenheim.tk",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.011440000000000",
   "Longitute 1": "12.497200000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Altöttinger Mieter Konvent (AMK)",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.amk-ev.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.234890000000000",
   "Longitute 1": "12.671030000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Unser Haus",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.unserhausev.wordpress.com",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.374320000000000",
   "Longitute 1": "10.888000000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Haus Ugental",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "48.683285396286200",
   "Longitute 1": "10.123188540438300",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Krähengarten",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.449740000000000",
   "Longitute 1": "11.055560000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Die Danz",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://die-danz.org",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "49.041640000000000",
   "Longitute 1": "12.130240000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "wohnopolis",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "wohnopolis.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.989180000000000",
   "Longitute 1": "11.031270000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Alte Feuerwache Weimar",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.feuerwache-weimar.de",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.980330000000000",
   "Longitute 1": "11.317560000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Baumhaus Weimar",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.baumhausweimar.com",
   "Description": "Member of Mietshäuser Syndikat",
   "Latitude 1": "50.978060000000000",
   "Longitute 1": "11.347320000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Left Bank Books",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.leftbankbooks.com/",
   "Description": "Bookstore that runs as a collective publishes & distributes radical works plus other used titles.",
   "Latitude 1": "47.609576954515800",
   "Longitute 1": "-122.340615892051000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kalabal!k",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://kalabalik.blackblogs.org/",
   "Description": "Anarchist library",
   "Latitude 1": "52.495178764091100",
   "Longitute 1": "13.430658655918600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "People's Bookshop Durham",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.facebook.com/Peoples-Bookshop-206528372717351/",
   "Description": "Independent, radical bookshop",
   "Latitude 1": "54.776759386413700",
   "Longitute 1": "-1.575097338632060",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Freedom Press bookshop",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://freedompress.org.uk/",
   "Description": "An independent, radical co-op controlled by its volunteers, Freedom runs Britain’s oldest anarchist press and its largest bookshop.",
   "Latitude 1": "51.516499176160600",
   "Longitute 1": "-0.070593769316035",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Jura Books",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.jura.org.au/",
   "Description": "Non-profit, volunteer-run anarchist bookshop",
   "Latitude 1": "-33.887402517727700",
   "Longitute 1": "151.159370307948000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "The Freedom Shop",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://freedomshopaotearoa.blogspot.com/",
   "Description": "Non-profit anarchist infoshop / bookshop",
   "Latitude 1": "-41.313416325869700",
   "Longitute 1": "174.779447897864000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "The Sparrows' Nest",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://thesparrowsnest.org.uk/",
   "Description": "Library and Archive for Anarchist and Radical History",
   "Latitude 1": "52.963072276755300",
   "Longitute 1": "-1.139221986392850",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Autonomous Administration of North and East Syria (Rojava)",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "",
   "Latitude 1": "34.42650679",
   "Longitute 1": "40.998572553989800",
   "Latitude 2": "35.162439899011100",
   "Longitute 2": "40.470627929179700",
   "Latitude 3": "35.769466577831900",
   "Longitute 3": "39.797271212704600",
   "Latitude 4": "35.847933332646300",
   "Longitute 4": "39.335320113543200",
   "Latitude 5": "35.666564426842900",
   "Longitute 5": "38.305630472227900",
   "Latitude 6": "36.190104984972400",
   "Longitute 6": "38.116720998029800",
   "Latitude 7": "36.458063038040300",
   "Longitute 7": "37.675045368186100",
   "Latitude 8": "36.635477599974600",
   "Longitute 8": "38.183238460747200",
   "Latitude 9": "36.855075845445300",
   "Longitute 9": "38.039560832483800",
   "Latitude 10": "36.916791041620100",
   "Longitute 10": "38.364165844486200",
   "Latitude 11": "36.708035810936700",
   "Longitute 11": "38.821805697473100",
   "Latitude 12": "36.365989451211800",
   "Longitute 12": "38.867037473522300",
   "Latitude 13": "36.729306784926000",
   "Longitute 13": "40.511352169931700",
   "Latitude 14": "36.990379882122500",
   "Longitute 14": "40.381702679481800",
   "Latitude 15": "37.131079343856000",
   "Longitute 15": "40.889722213236200",
   "Latitude 16": "37.112921066330200",
   "Longitute 16": "41.701967087207400",
   "Latitude 17": "37.321478107606100",
   "Longitute 17": "42.218160091226500",
   "Latitude 18": "37.076591445949100",
   "Longitute 18": "42.377572636585300",
   "Latitude 19": "36.590534890168500",
   "Longitute 19": "41.823424264623600",
   "Latitude 20": "36.52651092",
   "Longitute 20": "41.38314198"
 },
 {
   "Name": "IRL Infoshop",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.irlinfoshop.org/",
   "Description": "Collectively run independent community space and infoshop",
   "Latitude 1": "-37.79549959",
   "Longitute 1": "144.862863100000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Turnstyle Community Hub",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://turnstyle.org.au/pigglywiggly/",
   "Description": "Collectively run social space that provides resources for people and groups working on projects that benefit the community and/or for activist social change.",
   "Latitude 1": "-27.486751595657700",
   "Longitute 1": "153.022364692051000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Onsite Infoshop",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://onsiteinfoshopphilippines.wordpress.com/",
   "Description": "OnSite Info shop is a community-based resource center run by autonomous activists and anarchists and has local volunteers from OnSite Community.",
   "Latitude 1": "14.443102651486700",
   "Longitute 1": "121.048952251887000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "MACAO Milano",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.macaomilano.org/",
   "Description": "Independent center for art, culture and research, based in a former slaughterhouse",
   "Latitude 1": "45.458473135284400",
   "Longitute 1": "9.224431830683960",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rumah Api",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.facebook.com/klpunkhouse/",
   "Description": "",
   "Latitude 1": "3.128001333474070",
   "Longitute 1": "101.754482526108000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Tabadoul",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://tabadoul.org",
   "Description": "Arts and Cultural Space",
   "Latitude 1": "35.781202068692200",
   "Longitute 1": "-5.807370717694370",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Balay Tuklasan Likhaan",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "",
   "Description": "",
   "Latitude 1": "14.541152734353500",
   "Longitute 1": "121.089339541488000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Etnikobandido Infoshop",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://etnikobandidoinfoshop.wordpress.com/",
   "Description": "Buting Community Free Shop & Pantry",
   "Latitude 1": "14.555751130399100",
   "Longitute 1": "121.069047812165000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Feral Crust",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://feralcrust.noblogs.org/",
   "Description": "We teach kids based on self-determination and equality. Activities we usually do with the kids include gardening, preparing vegetarian meals, making art (drawing, stencils, silk- screen printing, craft-making from scraps and other recyclable materials, etc.), film showing, playing games, writing and reading, taking care of plants and animals, storytelling, playing music, listening to their family situation and problems and counseling responsibility and respect",
   "Latitude 1": "14.541526434406000",
   "Longitute 1": "121.090010097311000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "diffrakt",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://diffrakt.space/en/",
   "Description": "Collectively-run non-profit space, organizes series, conversations, workshops, book presentations, reading groups, screenings, performances",
   "Latitude 1": "52.488282536547500",
   "Longitute 1": "13.362109421028500",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Ruigoord",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.ruigoord.nl",
   "Description": "Ruigoord is an artist colony where art and life are integrated",
   "Latitude 1": "52.411042000000000",
   "Longitute 1": "4.748625000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "OT301",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.ot301.nl",
   "Description": "The OT301 has been squatted (1999) and bought (2006) by a diverse, international community that is united in an association called EHBK. We run this alternative, not-for-profit platform as a collective, in which housing, workspaces and public functions are combined to contribute to the arts, politics and subculture.",
   "Latitude 1": "52.360179000000000",
   "Longitute 1": "4.865615000000000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Sount Bend Commons",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://southbend.co/",
   "Description": "The South Bend Commons is dedicated to autonomous self-organization in Lakewood Heights/South Atlanta and the surrounding area",
   "Latitude 1": "33.705610517699200",
   "Longitute 1": "-84.379213269316000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Flyover Infoshop",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.facebook.com/flyoversocialcenter/",
   "Description": "The Flyover Infoshop is an activist and community resource center",
   "Latitude 1": "37.729044163584000",
   "Longitute 1": "-89.215719888989700",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "115 Legion",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.115legion.org/",
   "Description": "115 Legion is a multi-use community event space, print shop, and media lab in Squaxin and Nisqually territory",
   "Latitude 1": "47.043345768169800",
   "Longitute 1": "-122.901117001947000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Babylonia",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.babylonia.de/eine-seite/english/",
   "Description": "BABYLONIA is a centre for language-learning and for political meetings and discussions, for cultural presentations and exhibitions. Babylonia was founded in 1980 in a then-squatted factory in Berlin-Kreuzberg.",
   "Latitude 1": "52.497245192130700",
   "Longitute 1": "13.442324828931600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Center For People's Self-Determination",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.facebook.com/CPSDSTL/",
   "Description": "Community organization that seeks to develop self-determination on the North Side of Saint Louis.",
   "Latitude 1": "42.281526171287900",
   "Longitute 1": "-83.387617325620300",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Alfalfa House",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.alfalfahouse.org/",
   "Description": "Alfalfa House is a not-for-profit coop that aims to provide minimally packaged and minimally processed, affordable, wholesome, organic food to its members.",
   "Latitude 1": "-33.898187128002900",
   "Longitute 1": "151.174107830683000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Prinzessinnengarten",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://prinzessinnengarten.net/",
   "Description": "Community-organized garden on a plot of unused land in Kreuzberg",
   "Latitude 1": "52.502738671422600",
   "Longitute 1": "13.410133062176300",
   "Latitude 2": "52.502529682299100",
   "Longitute 2": "13.410819707648100",
   "Latitude 3": "52.503156646688900",
   "Longitute 3": "13.411892591197600",
   "Latitude 4": "52.503365632831700",
   "Longitute 4": "13.410905538332000",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Info Shop Marinduque",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://marindukanon.weebly.com/about.html",
   "Description": "Collapsible library, studies centre and archives about Marinduque's local history, cultural and arts studies",
   "Latitude 1": "14.599552224466100",
   "Longitute 1": "120.984134157770000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bentley Urban Farm",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://bentleyurbanfarm.com/",
   "Description": "Bentley Urban Farm (BUF) is an award winning upcycled market garden based in Bentley, Doncaster.",
   "Latitude 1": "53.543349917789600",
   "Longitute 1": "-1.145966869316030",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Fraguas Revive",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://fraguasrevive.blogspot.com",
   "Description": "Village expropriated under Franco, occupied since 2013",
   "Latitude 1": "41.104224196328100",
   "Longitute 1": "-3.134097336249720",
   "Latitude 2": "41.103108548216300",
   "Longitute 2": "-3.134140251591710",
   "Latitude 3": "41.102332434003600",
   "Longitute 3": "-3.133796928855850",
   "Latitude 4": "41.101718003747100",
   "Longitute 4": "-3.132595299280330",
   "Latitude 5": "41.101734173038000",
   "Longitute 5": "-3.131093262310940",
   "Latitude 6": "41.102397110538300",
   "Longitute 6": "-3.129376648631630",
   "Latitude 7": "41.102898351522700",
   "Longitute 7": "-3.128604172475950",
   "Latitude 8": "41.103399588681400",
   "Longitute 8": "-3.128303765082070",
   "Latitude 9": "41.104062509370400",
   "Longitute 9": "-3.128604172475950",
   "Latitude 10": "41.104531400451100",
   "Longitute 10": "-3.129655598354520",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "The Brotherhood Church",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.thebrotherhoodchurch.org/",
   "Description": "Originally a Christian anarchist pacifist community, now an inclusive community that farms on organic and ecological principles.",
   "Latitude 1": "53.670339553167300",
   "Longitute 1": "-1.221556857530720",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Barbacha",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://en.m.wikipedia.org/wiki/Barbacha",
   "Description": "An Algerian region that expelled government presence in 2001 and now organizes itself through assemblies",
   "Latitude 1": "36.616250720652400",
   "Longitute 1": "4.915882797448790",
   "Latitude 2": "36.586208020502000",
   "Longitute 2": "4.893566819617820",
   "Latitude 3": "36.565529644078500",
   "Longitute 3": "4.929615706883220",
   "Latitude 4": "36.529122254075800",
   "Longitute 4": "4.903179856221930",
   "Latitude 5": "36.508428598506600",
   "Longitute 5": "4.983860699149260",
   "Latitude 6": "36.581797098207200",
   "Longitute 6": "4.993817058489230",
   "Latitude 7": "36.583175538499300",
   "Longitute 7": "5.016133036320190",
   "Latitude 8": "36.608534446495100",
   "Longitute 8": "5.011326518018140",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Nueva Esperanza",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.catalystmedia.org.uk/international/celebrating-30-years-of-hope/",
   "Description": "Democratically organized peasant community",
   "Latitude 1": "13.332275102579700",
   "Longitute 1": "-88.690497827272300",
   "Latitude 2": "13.328662944800200",
   "Longitute 2": "-88.689274740025800",
   "Latitude 3": "13.328579426063000",
   "Longitute 3": "-88.683974695291000",
   "Latitude 4": "13.334049842411600",
   "Longitute 4": "-88.686335039100000",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Lakabe",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.lakabe.org/",
   "Description": "Village occupied by concientious objectors in the 1980s, still existing as a communitarian space",
   "Latitude 1": "42.873069977822000",
   "Longitute 1": "-1.346929002826280",
   "Latitude 2": "42.872071398671500",
   "Longitute 2": "-1.346982647003750",
   "Latitude 3": "42.871992769705800",
   "Longitute 3": "-1.344858337575610",
   "Latitude 4": "42.872999212902300",
   "Longitute 4": "-1.344740320385160",
   "Latitude 5": "42.873510291054600",
   "Longitute 5": "-1.346177984341580",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Acta Non Verba",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://anvfarm.org/",
   "Description": "Youth Urban Farm Projects; part of Omni Commons",
   "Latitude 1": "37.752322740289000",
   "Longitute 1": "-122.185027669316000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Counter Culture Labs",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.counterculturelabs.org/",
   "Description": "Community-Supported Microbiology Maker Space; part of Omni Commons",
   "Latitude 1": "37.835588684249100",
   "Longitute 1": "-122.263958623290000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Food Not Bombs",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://eastbayfoodnotbombs.org/",
   "Description": "All-volunteer collective that serves vegan food in Oakland and Berkeley six days a week; part of Omni Commons",
   "Latitude 1": "37.865915014404100",
   "Longitute 1": "-122.253197753974000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Sudo Room",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://sudoroom.org/",
   "Description": "Creative Community and Hackerspace; part of Omni Commons",
   "Latitude 1": "37.835135854422200",
   "Longitute 1": "-122.263993383440000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Lancaster Farm-Fresh Coop",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://lancasterfarmfresh.com/",
   "Description": "Cooperative of Organic Farms",
   "Latitude 1": "40.046272646367800",
   "Longitute 1": "-76.368431830683900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Mercato di CampiAperti",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.campiaperti.org/",
   "Description": "Campi Aperti is an association of producers and citizens that supports organic peasant agriculture and the right of communities to decide on the production of their own food, nutrition and land management.",
   "Latitude 1": "44.499116260724000",
   "Longitute 1": "11.318355290115900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Vag61 - Mercato Contadino di CampiAperti",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.campiaperti.org/",
   "Description": "Campi Aperti is an association of producers and citizens that supports organic peasant agriculture and the right of communities to decide on the production of their own food, nutrition and land management.",
   "Latitude 1": "44.502299598417900",
   "Longitute 1": "11.361613954834400",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "CampiAperti al Pratello - Mercato Contadino Biologico",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.campiaperti.org/",
   "Description": "Campi Aperti is an association of producers and citizens that supports organic peasant agriculture and the right of communities to decide on the production of their own food, nutrition and land management.",
   "Latitude 1": "44.497279640684000",
   "Longitute 1": "11.329169956295500",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Mercato Campi Aperti",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.campiaperti.org/",
   "Description": "Campi Aperti is an association of producers and citizens that supports organic peasant agriculture and the right of communities to decide on the production of their own food, nutrition and land management.",
   "Latitude 1": "44.514174362917000",
   "Longitute 1": "11.341014590682700",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Portazza - Mercato di Campiaperti",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.campiaperti.org/",
   "Description": "Campi Aperti is an association of producers and citizens that supports organic peasant agriculture and the right of communities to decide on the production of their own food, nutrition and land management.",
   "Latitude 1": "44.470979406099500",
   "Longitute 1": "11.386176729951700",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Grange Neuve",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.prolongomaif.ch/longo-ma%C3%AF/coop%C3%A9ratives/grange-neuve-fr/",
   "Description": "300 Hectare food coop; member of Longo Maï",
   "Latitude 1": "44.794642507248600",
   "Longitute 1": "0.401912930683965",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Cabrery",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.lacabrery.org/",
   "Description": "20 Hectare wine coop; member of Longo MaÏ",
   "Latitude 1": "43.788039808681200",
   "Longitute 1": "5.599978830683960",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Mas de Granier",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.prolongomaif.ch/longo-ma%C3%AF/coop%C3%A9ratives/mas-de-granier-fr/",
   "Description": "Food coop; member of Longo Maï",
   "Latitude 1": "43.660536643010600",
   "Longitute 1": "4.790798641896330",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Filature de Chantemerle",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://filature-longomai.org/",
   "Description": "Fabric spinning coop; member of Longo Maï",
   "Latitude 1": "44.932437169472800",
   "Longitute 1": "6.588237446025940",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Treynas",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.prolongomaif.ch/longo-ma%C3%AF/coop%C3%A9ratives/treynas-fr/",
   "Description": "200 Hectare food coop (mostly animals); member of Longo Maï",
   "Latitude 1": "44.916347210680100",
   "Longitute 1": "4.298849230683960",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Le Montois",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.prolongomaif.ch/longo-ma%C3%AF/coop%C3%A9ratives/le-montois-ch/",
   "Description": "11 Hectare agricultural coop; member of Longo Maï",
   "Latitude 1": "47.304094760692000",
   "Longitute 1": "7.220968211348250",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Maison de Bâle",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.prolongomaif.ch/longo-ma%C3%AF/coop%C3%A9ratives/la-maison-de-b%C3%A2le-ch/",
   "Description": "Organizational hub of Longo Maï",
   "Latitude 1": "47.563255392333700",
   "Longitute 1": "7.584787315341980",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hof Ulenkrug",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.prolongomaif.ch/longo-ma%C3%AF/coop%C3%A9ratives/ulenkrug-de/",
   "Description": "50 Hectare cooperative farm; member of Longo Maï",
   "Latitude 1": "53.953440369180400",
   "Longitute 1": "12.789234015153000",
   "Latitude 2": "53.952935301899100",
   "Longitute 2": "12.805198522370500",
   "Latitude 3": "53.959955188665100",
   "Longitute 3": "12.805370183738500",
   "Latitude 4": "53.956975668863900",
   "Longitute 4": "12.792152258407800",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hof Stopar",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.prolongomaif.ch/longo-ma%C3%AF/coop%C3%A9ratives/stopar-at/",
   "Description": "17 Hectare cooperative farm; member of Longo Maï",
   "Latitude 1": "46.492292355778100",
   "Longitute 1": "14.630475970836600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Zeleny Hay",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.prolongomaif.ch/longo-ma%C3%AF/coop%C3%A9ratives/zeleny-hay-tk/",
   "Description": "Cooperative farm in a more or less abandoned village; producer of meat, cheese and apple juice; member of Longo Maï",
   "Latitude 1": "47.919667210866100",
   "Longitute 1": "29.376281876259100",
   "Latitude 2": "47.915525510511500",
   "Longitute 2": "29.383105415634300",
   "Latitude 3": "47.915583036397900",
   "Longitute 3": "29.385165352049500",
   "Latitude 4": "47.916877351934900",
   "Longitute 4": "29.386409896967000",
   "Latitude 5": "47.917711449236000",
   "Longitute 5": "29.384822029313600",
   "Latitude 6": "47.919839774519300",
   "Longitute 6": "29.387396949832600",
   "Latitude 7": "47.921249022808700",
   "Longitute 7": "29.384779113971700",
   "Latitude 8": "47.920357462025200",
   "Longitute 8": "29.383362907686200",
   "Latitude 9": "47.922284364436300",
   "Longitute 9": "29.379457611565800",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "ZAD",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://zadforever.blog/about/",
   "Description": "Area occupied to prevent the construction of an airport; now an experiment in communal living occupying some 1600 hectares",
   "Latitude 1": "47.357002840729600",
   "Longitute 1": "-1.745664099823650",
   "Latitude 2": "47.348396808413600",
   "Longitute 2": "-1.746350745295370",
   "Latitude 3": "47.350897354336800",
   "Longitute 3": "-1.713563424020650",
   "Latitude 4": "47.358572708770000",
   "Longitute 4": "-1.713048439916860",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Ackermannshof",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/ackermannshof/",
   "Description": "Kunst, Kultur, Gastronomie",
   "Latitude 1": "47.5631479",
   "Longitute 1": "7.58467749",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Akademie für anthroposophische Pädagogik",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/akademie-fuer-anthroposophische-paedagogik/",
   "Description": "Bildungseinrichtung",
   "Latitude 1": "47.4820597",
   "Longitute 1": "7.6187035",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Alltag",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/alltag/",
   "Description": "Wohnen und Sozial-/Kulturarbeit",
   "Latitude 1": "52.4795799",
   "Longitute 1": "13.4330171",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Alte Feuerwehr Viktoria",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/alte-feuerwache-viktoria/",
   "Description": "Quartierwerkstatt, Restaurant, Tagesschule, soziale Projekte, Integrationsprojekte, Sport- und Bewegungslokale, Ateliers für Kunstschaffende, Kleingewerbe, Urban Gardening u.v.m.",
   "Latitude 1": "46.9545812",
   "Longitute 1": "7.45215029",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Alter Weg 10",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/alter-weg-10/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "51.436331",
   "Longitute 1": "7.36549969",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Am Kräherwald 313",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/am-kraeherwald/",
   "Description": "Zweifamilienhaus",
   "Latitude 1": "48.77491",
   "Longitute 1": "9.13731",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Am Landgut 6-7a",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/am-landgut-6-7a/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "52.0946499",
   "Longitute 1": "13.99661",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Am Landgut 8",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/am-landgut-8/",
   "Description": "Diverse Zwischennutzungen",
   "Latitude 1": "52.0947198",
   "Longitute 1": "13.9963764",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Am Weiher",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/am-weiher/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "50.9794934",
   "Longitute 1": "11.3235439",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Amerbach Studios",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/amerbach-studios/",
   "Description": "Vorderhaus: Vier 3.5-Zi-Whg., ein Studiozimmer, eine 2.5-Zi-Attikawhg. Hinterhaus: Atelier-, Büro- und Lagerräume",
   "Latitude 1": "47.5694424",
   "Longitute 1": "7.5940252",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Atelier Stella",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/atelier-stella/",
   "Description": "Atelier und Arbeitsplatz für Kunstschaffende, Temporäres Büro und Treffpunkt für Vereine und Initiativen",
   "Latitude 1": "47.5777966",
   "Longitute 1": "7.586821",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Ausbildungsstätte für plastisch-künstlerische Therapie",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/ausbildungsstaette-fuer-plastisch-kuenstlerische-therapie/",
   "Description": "Bildungseinrichtung",
   "Latitude 1": "47.4819408",
   "Longitute 1": "7.6193504",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bain da Chauenas",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/bain-da-chauenas/",
   "Description": "biologisch-dynamischer Landwirtschaftsbetrieb",
   "Latitude 1": "46.8078955",
   "Longitute 1": "10.3161241",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bärenfelserstrasse 34",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/baerenfelserstrasse-34/",
   "Description": "Wohnhaus mit 5 x 1-Zimmer-Whg. 3 x 2-Zimmer-Whg. 5 x 3-Zimmer-Whg. 5 x 4- bis 5-Zimmer-Whg.",
   "Latitude 1": "47.5676924",
   "Longitute 1": "7.5878836",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Baselstrasse 8",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/baselstrasse-8/",
   "Description": "Stockwerk-Eigentumswohnung, Einstellplatz",
   "Latitude 1": "47.4966702",
   "Longitute 1": "7.6210591",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Beginenhof Tännich",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/beginenhof-taennich/",
   "Description": "Wohnraum für 4 bis 5 Personen, kulturelle Arbeit, mobile Tierarztpraxis",
   "Latitude 1": "53.89304",
   "Longitute 1": "13.8342199",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bergstrasse",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/bergstrasse/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "52.2398147",
   "Longitute 1": "13.808187",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Blumenweg 2",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/blumenweg-2/",
   "Description": "Einfamilienhaus",
   "Latitude 1": "47.4878922",
   "Longitute 1": "7.6151643",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bornstrasse 11",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/bornstrasse-11/",
   "Description": "Wohnhaus 2 x 1-Zimmer-Whg. 13 x 2-Zimmer-Whg. 2 x 3-Zimmer-Whg. 1 x 4-Zimmer-Whg. 2 x 5-Zimmer-Whg.",
   "Latitude 1": "52.4663522",
   "Longitute 1": "13.3234809",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Brachmattstrasse 14",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/brachmattstrasse-14/",
   "Description": "Stockwerk-Eigentumswohnung, Einstellplatz",
   "Latitude 1": "47.4950848",
   "Longitute 1": "7.6168469",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bruderholzrain 27",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/bruderholzrain/",
   "Description": "5 Wohnungen",
   "Latitude 1": "47.5373892",
   "Longitute 1": "7.5941023",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Casa Andrea Cristoforo",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/casa-andrea-cristoforo/",
   "Description": "Medizinische Einrichtung",
   "Latitude 1": "46.155334",
   "Longitute 1": "8.76047429",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Castel Forgues",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/castel-forgues/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "43.2747839",
   "Longitute 1": "-0.3973029",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Circular Economy House",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/circular-economy-house/",
   "Description": "Ökogewerbe und Wohnen",
   "Latitude 1": "52.4795185",
   "Longitute 1": "13.4330477",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Delsbergerallee 42",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/delsbergerallee-42/",
   "Description": "4 Drei-Zimmer-Whg. und eine 4-Zimmer-Maisonette-Whg.",
   "Latitude 1": "47.5409334",
   "Longitute 1": "7.5997864",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Dischtelfink",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/dischtelfink/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.5403832",
   "Longitute 1": "7.5735922",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Dornacherstrasse 49-53",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/dornacherstrasse-49-53/",
   "Description": "4 x 4-Zi.-Whg. 3 x 4-Zi.-Maisonette-Whg. 3 x 2-Zi.-Whg. 3 x Einzelzimmer Kindertagesstätte im EG u. 1. OG mit ca. 542 m2",
   "Latitude 1": "47.5446704",
   "Longitute 1": "7.58629169",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Dorndach",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/dorndach/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.4853335",
   "Longitute 1": "7.612144",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Dorneckstrasse 133",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/dorneckstrasse-133/",
   "Description": "Einfamilienhaus",
   "Latitude 1": "47.4832271",
   "Longitute 1": "7.6249537",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Efringerstrasse 71",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/efringerstrasse-71/",
   "Description": "Wohnhaus mit 6 x 3-Zimmer-Whg. und eine 2-Zimmer-Whg.",
   "Latitude 1": "47.5691427",
   "Longitute 1": "7.59612409",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Ehemaliges Stummfilmkino Delphi",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/ehemaliges-stummfilmkino-delphi/",
   "Description": "Spielstätte und Kulturzentrum für darstellende Künste",
   "Latitude 1": "52.5518551",
   "Longitute 1": "13.4311845",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Eine-Welt-Zentrum",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/am-sudhaus/",
   "Description": "Büros, insg. 3350 m<sup>2</sup> BGF",
   "Latitude 1": "52.4794211",
   "Longitute 1": "13.4306652",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Engeldamm 30",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/engeldamm-30/",
   "Description": "Wohnhaus mit einer Nutzfläche von ca. 1450 m2",
   "Latitude 1": "52.5062734",
   "Longitute 1": "13.4261138",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Englitzer Eck",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/englitzer-eck/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.6172539",
   "Longitute 1": "9.72454708",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Eugen-Kolisko-Haus",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/eugen-kolisko-haus/",
   "Description": "Alterswohn- und Lebensgemeinschaft für 9 Personen",
   "Latitude 1": "48.78176",
   "Longitute 1": "8.71431",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "ExRotaprint",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/exrotaprint/",
   "Description": "Gewerbebetriebe, soziale Einrichtungen, Ateliers",
   "Latitude 1": "52.5501429",
   "Longitute 1": "13.3703084",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Fischenrüti",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/fischenrueti/",
   "Description": "Wohnen, Ateliers, Spielgruppe, interkultureller Garten",
   "Latitude 1": "47.2490814",
   "Longitute 1": "8.6010476",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Freie Gemeinschaftsbank",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/freie-gemeinschaftsbank/",
   "Description": "Bank",
   "Latitude 1": "47.5467782",
   "Longitute 1": "7.5854795",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Gässliacherweg 2-20",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/gaessliacherweg-2-20/",
   "Description": "10 Einfamilienhäuser",
   "Latitude 1": "47.3852211",
   "Longitute 1": "8.1429215",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Geburtshaus Matthea",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/geburtshaus-matthea/",
   "Description": "Geburtshaus, Bildungsangebote, Café, Kleingewerbe, Büros, 17 Wohnungen",
   "Latitude 1": "47.565815",
   "Longitute 1": "7.5912666",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Geisshof",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/geisshof/",
   "Description": "Biologisch-dynamischer Landwirtschaftsbetrieb",
   "Latitude 1": "47.474006",
   "Longitute 1": "8.24494149",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Gempenstrasse 5",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/gempenstrasse-5/",
   "Description": "4 Wohnungen und Café im Erdgeschoss",
   "Latitude 1": "47.5451537",
   "Longitute 1": "7.5877062",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Georg-Schwarz-Strasse / Merseburger Strasse",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/georg-schwarz-strasse-merseburger-strasse/",
   "Description": "Wohn- und Geschäftshaus",
   "Latitude 1": "51.3379309",
   "Longitute 1": "12.3270999",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Gleiserei",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/gleiserei/",
   "Description": "Wohnen, soziale Zwecke (in Planung)",
   "Latitude 1": "51.3448637",
   "Longitute 1": "12.4160303",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Grenze",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/grenze/",
   "Description": "Wohnhaus und Atelierräume für Kulturschaffende",
   "Latitude 1": "47.5764115",
   "Longitute 1": "7.5731256",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Gut Oberhofen",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/gut-oberhofen/",
   "Description": "biologisch-dynamischer Landwirtschaftsbetrieb, Tischlerei, Wohnen",
   "Latitude 1": "47.9514597",
   "Longitute 1": "13.3065816",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Gutshof Neuendorf im Sande",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/gutshof-neuendorf-im-sande/",
   "Description": "Wohnen (insg. knapp 2000 qm Wohnfläche), Aufarbeitung des historischen Erbes, Aus- und Weiterbildung für handwerkliche und landwirtschaftliche Berufe, politische Bildungsarbeit, ökologische Landwirtschaft (ca. 25 ha)",
   "Latitude 1": "52.3970053",
   "Longitute 1": "14.1015311",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Halle Tanzbühne Berlin",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/halle-tanzbuehne-berlin/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "52.5408663",
   "Longitute 1": "13.4076336",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hammerwerke Worblaufen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/hammerwerke-worblaufen/",
   "Description": "Gewerbenutzung (Metallbearbeitung), vier Wohnungen",
   "Latitude 1": "46.9767073",
   "Longitute 1": "7.4643911",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hauptstrasse 83",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/hauptstrasse-83/",
   "Description": "Hausgemeinschaft mit 8–9 Bewohnern",
   "Latitude 1": "47.5403195",
   "Longitute 1": "7.57356089",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Haus Akron",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/haus-akron/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.61831",
   "Longitute 1": "9.72473",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Haus Jaggi",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/haus-jaggi/",
   "Description": "Zweifamilienhaus",
   "Latitude 1": "47.4820537",
   "Longitute 1": "7.621068",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Haus Mercurial",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/haus-mercurial/",
   "Description": "Mehrgenerationenhaus",
   "Latitude 1": "47.6806775",
   "Longitute 1": "9.8202408",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Haus Moser",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/haus-moser/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.4835944",
   "Longitute 1": "7.619356",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Haus Perotti I",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/haus-perotti-i/",
   "Description": "Einfamilienhaus",
   "Latitude 1": "47.4807352",
   "Longitute 1": "7.61897969",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Haus Perotti II",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/haus-perotti-ii/",
   "Description": "Zweifamilienhaus",
   "Latitude 1": "47.4804516",
   "Longitute 1": "7.6189119",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Haus Tuch",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/haus-tuch/",
   "Description": "Wohnhaus mit vier Wohnungen",
   "Latitude 1": "47.4821975",
   "Longitute 1": "7.61616709",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Haus Unger",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/haus-unger/",
   "Description": "Wohnhaus mit zwei Wohnungen",
   "Latitude 1": "47.4836895",
   "Longitute 1": "7.6168222",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hinterrüti",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/hinterrueti/",
   "Description": "Kulturatelier, Einfamilienhaus",
   "Latitude 1": "47.2371632",
   "Longitute 1": "8.605398",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hochstrasse 263-267",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/hochstrasse-263-267/",
   "Description": "Insgesamt 26 2.5-5.5 Zimmer-Wohnungen, Einstellhalle",
   "Latitude 1": "47.7143015",
   "Longitute 1": "8.6439775",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hof Les Advins",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/hof-les-advins/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.4793951",
   "Longitute 1": "7.1511949",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hof Marchstein",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/hof-marchstein/",
   "Description": "biologisch-dynamischer Landwirtschaftsbetrieb, Sozialpädagogik",
   "Latitude 1": "47.3405853",
   "Longitute 1": "7.5592628",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Höhere Fachschule für anthroposophische Heilpädagogik",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/hoehere-fachschule-fuer-anthroposophische-heilpaedagogik/",
   "Description": "Bildungseinrichtung",
   "Latitude 1": "47.4824552",
   "Longitute 1": "7.6187054",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Insel",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/insel/",
   "Description": "76 Wohnungen, Café, Gemeinschaftsküche, Versammlungsraum",
   "Latitude 1": "51.2256055",
   "Longitute 1": "6.80167559",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Jülicherstrasse 30",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/juelicherstrasse-30/",
   "Description": "4 Gewerbe- und 33 Wohneinheiten",
   "Latitude 1": "52.55465",
   "Longitute 1": "13.3925798",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Jungstrasse 7",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/jungstrasse-7/",
   "Description": "5 3-Zi.-Wohnungen",
   "Latitude 1": "47.5676882",
   "Longitute 1": "7.5801998",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Jurastrasse 50",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/jurastrasse-50/",
   "Description": "Atelier, Werkstatt und Büros",
   "Latitude 1": "47.5415216",
   "Longitute 1": "7.5903312",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Juraweg 2-6",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/erli/",
   "Description": "Wohnen, Kunstarchiv",
   "Latitude 1": "47.4835891",
   "Longitute 1": "7.62137",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kamerun 12",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/kamerun-12/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "52.25772",
   "Longitute 1": "13.66426",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "KlausHaus",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/klaushaus/",
   "Description": "Gemeinschafts-Wohnungen, gewerbliche und kulturelle Nutzungen",
   "Latitude 1": "51.3383806",
   "Longitute 1": "12.4071207",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kloster Schönthal",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/kloster-schoenthal/",
   "Description": "Biodynamische Landwirtschaft, Ausstellungen, Veranstaltungen, Gästezimmer, Wohnen",
   "Latitude 1": "47.3595088",
   "Longitute 1": "7.7733398",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kohliberg 3-9",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/kohliberg-3-9/",
   "Description": "Wohnen, Büro",
   "Latitude 1": "47.4792335",
   "Longitute 1": "7.6173753",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kollektivhaus Wurze",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/kollektivhaus-wurze/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "51.3385018",
   "Longitute 1": "12.4072218",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kosthaus Lenzburg",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/kosthaus-lenzburg/",
   "Description": "Tagungshaus, Ausbildungsstelle, Kantine",
   "Latitude 1": "47.3919301",
   "Longitute 1": "8.1734349",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Künstlerhaus DIE WACHE",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/kuenstlerhaus-die-wache/",
   "Description": "12 Proberäume, 3 Studios, 8 Büros, ein Musiksaal 110 m2, insgesamt ca. 823 m2 Mietfläche, grosser Hinterhof und Garten",
   "Latitude 1": "52.5408845",
   "Longitute 1": "13.4076382",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "KunstRaumRhein (Haus Julian)",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/kulturraumrhein-haus-julian/",
   "Description": "Kulturbetrieb (seit 2015), studentisches Wohnen, Gästezimmer",
   "Latitude 1": "47.4822394",
   "Longitute 1": "7.61815739",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Landgut Pretschen",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/landgut-pretschen/",
   "Description": "biologisch-dynamischer Landwirtschaftsbetrieb",
   "Latitude 1": "52.09039",
   "Longitute 1": "13.99778",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Le Conserve",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/le-conserve/",
   "Description": "Wohnen, Werkstätten, Büros (in Planung), 1250 m2 Wohn- und Nutzfläche",
   "Latitude 1": "51.3341919",
   "Longitute 1": "12.3207009",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Lohweg 20",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/richenmatt/",
   "Description": "ein Mehr- und drei Reiheneinfamilienhäuser",
   "Latitude 1": "47.4843822",
   "Longitute 1": "7.5424311",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Lothringer Strasse 7",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/lothringer-strasse/",
   "Description": "4 Wohnungen",
   "Latitude 1": "47.5660072",
   "Longitute 1": "7.5795176",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Lottbeker Weg 133 d-e, 135 a",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/lottbeker-weg-133-d-e-135-a/",
   "Description": "7 Wohnungen, Wohnfläche insg. 494 m2",
   "Latitude 1": "53.67745",
   "Longitute 1": "10.13517",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Markthalle Basel",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/markthalle-basel/",
   "Description": "Gastronomie, Handel und Dienstleistungen",
   "Latitude 1": "47.5489454",
   "Longitute 1": "7.58725709",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Max-Brauer-Allee 24",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/max-brauer-allee-24/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "53.5485019",
   "Longitute 1": "9.93651959",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Melchior Schiner Hus",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/melchior-schiner-hus/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "46.4074978",
   "Longitute 1": "8.1563862",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Murbacherstrasse 24",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/murbacherstrasse-24/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.5695623",
   "Longitute 1": "7.57742729",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Neckarstrasse 20-23",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/neckarstrasse-20-23/",
   "Description": "7 2-3-Zimmer-Wohnungen, 2 Garagen",
   "Latitude 1": "52.48017",
   "Longitute 1": "13.43427",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Neuensteinerstrasse 14",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/neuensteinerstrasse-14/",
   "Description": "Wohnhaus mit 5 Drei-Zimmer-Whg.",
   "Latitude 1": "47.5402351",
   "Longitute 1": "7.6006314",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Neues Theater am Bahnhof",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/neues-theater-am-bahnhof/",
   "Description": "Theater",
   "Latitude 1": "47.4884342",
   "Longitute 1": "7.6114568",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Nucleo 1",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/nucleo-1/",
   "Description": "Kindertagesstätte",
   "Latitude 1": "46.066216",
   "Longitute 1": "8.9761001",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Nyitott Műhely",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/nyitott-muhely/",
   "Description": "Kulturveranstaltungen, Café, Bibliothek, Lager (insg. 233 m2), 1 Wohnung 72 m2",
   "Latitude 1": "47.4993502",
   "Longitute 1": "19.023112",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Obere Gasse 2-6",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/obere-gasse/",
   "Description": "9 Wohnungen",
   "Latitude 1": "47.4915427",
   "Longitute 1": "7.6216388",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Pfeffingerhof",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/pfeffingerhof/",
   "Description": "Atelier-, Therapie- und Büroräume, Hostel",
   "Latitude 1": "47.4939103",
   "Longitute 1": "7.6144411",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "RAUM-ZEIT-STILLE",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/raum-zeit-stille/",
   "Description": "Kunst- und Kulturprojekt, Atelier- und Seminarhaus",
   "Latitude 1": "47.4261454",
   "Longitute 1": "7.4231704",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rheingasse 17",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/rheingasse/",
   "Description": "in Planung",
   "Latitude 1": "47.5603979",
   "Longitute 1": "7.5919369",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Riederenhof",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/riederenhof/",
   "Description": "Biologisch-dynamischer Landwirtschaftsbetrieb",
   "Latitude 1": "47.5067294",
   "Longitute 1": "7.84858989",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Riehenring 107",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/riehenring-107/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.565858",
   "Longitute 1": "7.5976425",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rigaerstrasse 78",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/rigaerstrasse-78/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "52.5166876",
   "Longitute 1": "13.4641814",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rudolf Steiner Schule Basel",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/rudolf-steiner-schule-basel/",
   "Description": "Schule",
   "Latitude 1": "47.5329995",
   "Longitute 1": "7.6007585",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rudolf Steiner Schule Münchenstein",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/rudolf-steiner-schule-muenchenstein/",
   "Description": "Schule",
   "Latitude 1": "47.516172",
   "Longitute 1": "7.6106577",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Sandrainstrasse 83",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/sandrainstrasse-83/",
   "Description": "6 Drei-Zimmerwohnungen und 1 Atelierraum",
   "Latitude 1": "46.9332362",
   "Longitute 1": "7.44505419",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Schafgässlein 4-6",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/schafgaesslein/",
   "Description": "Gebetsraum, Vereinsräume, Arztpraxis, 3 Wohnungen",
   "Latitude 1": "47.5603162",
   "Longitute 1": "7.5922182",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Schellbergstrasse 44",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/schellbergstrasse-44/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "48.7842",
   "Longitute 1": "9.20009",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Schokoladen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/schokoladen/",
   "Description": "rd. 20 Wohnungen, Ateliers und Werkstätten, Theater, Clubräume",
   "Latitude 1": "52.52974",
   "Longitute 1": "13.39659",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Schönerhausen",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/schoenerhausen/",
   "Description": "Vor Sanierung und Umbau 40 Wohneinheiten (2430 m2 Wohnfläche), Remise, grosser Garten",
   "Latitude 1": "51.3446348",
   "Longitute 1": "12.4220794",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Schulgasse 10-12",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/schulgasse-10-12/",
   "Description": "1 x 3.5-Zi-Whg., 2 x 3-Zi-Whg., 1 x 2-Zi-Whg., 8 Einzelzimmer",
   "Latitude 1": "47.4841813",
   "Longitute 1": "7.6128037",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Sempacherstrasse 64-66",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/sempacherstrasse-64-66/",
   "Description": "Zwei Häuser mit je vier 3-Zi.-Whg., einer Maisonette-Dach-Whg. und einem Studio",
   "Latitude 1": "47.5428036",
   "Longitute 1": "7.58658919",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Sempacherstrasse 68",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/sempacherstrasse-68/",
   "Description": "Vier 4-Zi.-Whg., eine 4.5 Zi.-Whg. und eine loftähnliche, offene Dachwohnung",
   "Latitude 1": "47.5426869",
   "Longitute 1": "7.58646929",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Seumestrasse 14",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/seumestrasse-14/",
   "Description": "30 Wohnungen, eine Gewerbeeinheit",
   "Latitude 1": "52.5103549",
   "Longitute 1": "13.4627971",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Siedlung Lolibach",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/siedlung-lolibach/",
   "Description": "4 Gebäude mit einem Gemeinschaftsraum, 1 Büro und 42 Wohnungen von 1.5 bis 6.5 Zimmern",
   "Latitude 1": "47.4696313",
   "Longitute 1": "7.605116",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Siedlung Neue Heimat",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/siedlung-neue-heimat/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.4709996",
   "Longitute 1": "7.60792168",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Sonnenbergstrasse 55-57",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/sonnenbergstrasse-55-57/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.3691623",
   "Longitute 1": "8.5655332",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Sonnenhof Preda",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/sonnenhof-preda/",
   "Description": "Gasthaus und Gruppenhaus",
   "Latitude 1": "46.5875169",
   "Longitute 1": "9.77967139",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Sonnenhügel &#8211; Haus der Gastfreundschaft",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/sunnehuegel-haus-der-gastfreundschaft/",
   "Description": "Lebensgemeinschaft",
   "Latitude 1": "46.9561505",
   "Longitute 1": "8.0219234",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Spiekermannstrasse 30",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/spiekermannstrasse-30/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "52.5538899",
   "Longitute 1": "13.4283401",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Stadtgarten H17",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/stadtgarten-h17/",
   "Description": "Garten",
   "Latitude 1": "51.3331012",
   "Longitute 1": "12.3334824",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Staffelenweg 3-5",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/staffelenweg-3-5/",
   "Description": "6 Wohnungen",
   "Latitude 1": "47.4664565",
   "Longitute 1": "7.8542916",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Stechlin Institut",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/stechlin-institut/",
   "Description": "Kunst und Therapie",
   "Latitude 1": "53.1264934",
   "Longitute 1": "13.069421",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Thulestrasse 51",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/thulestrasse-51/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "52.55532",
   "Longitute 1": "13.42467",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Togostrasse 80",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/togostrasse-80/",
   "Description": "9 Wohnungen, Künstleratelier",
   "Latitude 1": "52.5490395",
   "Longitute 1": "13.3473774",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Turnerstrasse 28",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/turnerstrasse-28/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.5608268",
   "Longitute 1": "7.6003381",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Unterbühlhof",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/unterbuehlhof/",
   "Description": "Landschaftspflege, Kinder- und Jugendarbeit, Wohnen",
   "Latitude 1": "47.672813",
   "Longitute 1": "8.914756",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Unternehmen Mitte",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/unternehmen-mitte/",
   "Description": "ca. 4000 m2 BGF für Wohnungen, Büros, Läden, Café, Restaurant und kulturelle Aktivitäten aller Art",
   "Latitude 1": "47.5565738",
   "Longitute 1": "7.5883694",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Via da Liuns",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/via-da-liuns/",
   "Description": "3,5-Zimmer-Wohnung",
   "Latitude 1": "46.7997804",
   "Longitute 1": "10.2999139",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Via Motta 31",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/via-motta-31/",
   "Description": "1.5-Zimmer-Wohnung, Stellplatz",
   "Latitude 1": "46.0003897",
   "Longitute 1": "8.9480942",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Villa Eichhorn",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/villa-eichhorn/",
   "Description": "Villa mit 14 Zimmern, 3 Küchen und 3 Badezimmern für vier bis sieben Mitbewohner",
   "Latitude 1": "47.5716841",
   "Longitute 1": "9.3786433",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "VOLLGUT",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/vollgut/",
   "Description": "diverse Gewerbe- und Kulturbetriebe verteilt auf rd. 38’000 m2 Bruttogeschossfläche",
   "Latitude 1": "52.4795799",
   "Longitute 1": "13.4330172",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Waldorfschule und -kindergarten Barnim",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/waldorfschule-und-kindergarten-barnim/",
   "Description": "Schule, Kindertagesstätte",
   "Latitude 1": "52.8363",
   "Longitute 1": "13.7286029",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Weidenhof",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/weidenhof/",
   "Description": "Landwirtschaftsbetrieb",
   "Latitude 1": "53.1247045",
   "Longitute 1": "9.6360827",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Wiesenstrasse 29",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/wiesenstrasse-29/",
   "Description": "Ateliers und gemeinnützige Projekte",
   "Latitude 1": "52.5487733",
   "Longitute 1": "13.3714054",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Wiesenstrasse 30",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/wiesenstrasse-30/",
   "Description": "28 Wohnungen und eine gemeinnützige Einrichtung",
   "Latitude 1": "52.5486249",
   "Longitute 1": "13.3710217",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Wohnheim Sonnhalde",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/wohnheim-sonnhalde/",
   "Description": "Betreutes Wohnen, öffentliches Café",
   "Latitude 1": "47.4719468",
   "Longitute 1": "7.6074868",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Wohninitiative Berg",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/wohninitiative-berg/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "48.3809052",
   "Longitute 1": "9.2880568",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Zeppelinstrasse 29",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/zeppelinstrasse-29/",
   "Description": "4-6 Gemeinschafts-Wohnungen, Werkstätten, diverse Vereinsnutzungen",
   "Latitude 1": "52.3942479",
   "Longitute 1": "13.0379841",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Ziegelackerweg 3",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/ziegelackerweg-3/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.4902811",
   "Longitute 1": "7.6232135",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Ziegelackerweg 6",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/ziegelackerweg-6/",
   "Description": "Einfamilienhaus",
   "Latitude 1": "47.4896597",
   "Longitute 1": "7.6237156",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Zum Rauacker 6",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/zum-rauacker-6/",
   "Description": "Wohnprojekt in Planung",
   "Latitude 1": "47.489843",
   "Longitute 1": "7.6156617",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Zürcherstrasse 8",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryon.ch/liegenschaft/zuercherstrasse-8/",
   "Description": "Member of Edith Maryon Stiftung",
   "Latitude 1": "47.4817137",
   "Longitute 1": "7.62070289",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Aukera",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.aukera-lcdp.com/",
   "Description": "Permaculture and conservation project",
   "Latitude 1": "43.42466475",
   "Longitute 1": "-1.394769646",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Recartografías",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://recartografias.es/",
   "Description": "Commoning project in previously abandoned village Mas Blanco",
   "Latitude 1": "40.103794816971000",
   "Longitute 1": "-0.682853129221920",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Anarres Housing Co-op",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://network23.org/anarreshousingcoop/",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "51.458768198473500",
   "Longitute 1": "-2.548464888356940",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Catfish Housing Co-op",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalroutes.org.uk/list-of-members/housing-co-ops/252-catfish.html",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "53.649906945249600",
   "Longitute 1": "-1.809031515264030",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Cornerstone Housing Co-op",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://cornerstonehousing.org.uk/blog/",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "53.819200117957800",
   "Longitute 1": "-1.533304401763220",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Coventry Peace House",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://coventrypeacehouse.wixsite.com/coventry-peace-house",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "52.419859298272500",
   "Longitute 1": "-1.497456201815800",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Dragonfly Housing Cooperative",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalroutes.org.uk/list-of-members/housing-co-ops/dragonfly.html",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "51.736314853960900",
   "Longitute 1": "-1.228775844170750",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Equinox Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalroutes.org.uk/list-of-members/housing-co-ops/equinox.html",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "53.451551761122000",
   "Longitute 1": "-2.198714886436310",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Golem Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://golemcoop.blogspot.com/?fbclid=IwAR1JhyTM7dEDiPCY1tVtGwFzXkyIwyahJk663ZedIulBdpr5TJxG9AZGYnk",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "51.621612017849600",
   "Longitute 1": "-3.949041365339750",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Gung Ho Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://gungho.org.uk/",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "52.436890474837600",
   "Longitute 1": "-1.916802475325120",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Heartwell Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalroutes.org.uk/list-of-members/housing-co-ops/239-heartwell-housing-co-op.html",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "50.467100314471100",
   "Longitute 1": "-3.674897971205980",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Highbury Farm aka Stepping Stones",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.highburyfarm.org.uk/",
   "Description": "Housing co-op and farm; member of Radical Routes",
   "Latitude 1": "51.780548927990200",
   "Longitute 1": "-2.669071205138170",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "The Hive",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalroutes.org.uk/list-of-members/housing-co-ops/the-hive.html",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "53.800602390053800",
   "Longitute 1": "-1.757914686423130",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kindling Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://kindlingcoop.org/",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "51.754339092913700",
   "Longitute 1": "-1.256922692044750",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Mary Ann Johnson Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://maryannjohnsonhousingcoop.com/",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "51.555630810995800",
   "Longitute 1": "-0.090678846023784",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Neds Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalroutes.org.uk/list-of-members/housing-co-ops/neds.html",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "52.971848038769900",
   "Longitute 1": "-1.163127630630370",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Nutclough / Zion Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalroutes.org.uk/list-of-members/housing-co-ops/nutclough-zion.html",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "53.744527298435800",
   "Longitute 1": "-2.009396286425260",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Persons Unknown",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.personsunknowncoop.com/",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "53.398861131633300",
   "Longitute 1": "-2.936588915273510",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Random Camel Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://randomcamelcoop.wordpress.com/",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "52.054094532112700",
   "Longitute 1": "1.156857398170600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Redcurrant Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://redcurranthousingcoop.wordpress.com/",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "55.906298045615700",
   "Longitute 1": "-3.377655244012620",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rockdove Rising Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.facebook.com/rockdoverising/",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "53.469909488082600",
   "Longitute 1": "-2.248057401776430",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rose Howey Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.facebook.com/Rose-Howey-Housing-Co-operative-412346999562581/",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "53.388311610952700",
   "Longitute 1": "-2.954005749226110",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Share Instead Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalroutes.org.uk/list-of-members/housing-co-ops/share-instead.html",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "53.363492307480600",
   "Longitute 1": "-1.480823442263670",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Skylark Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://skylarkhousingcoop.wordpress.com/",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "50.829747988786500",
   "Longitute 1": "-0.142075344203984",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Talamh",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.talamh.org.uk/",
   "Description": "Housing co-op and farm; member of Radical Routes",
   "Latitude 1": "55.600621945501900",
   "Longitute 1": "-3.844455423296030",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Walden Pond",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalroutes.org.uk/list-of-members/housing-co-ops/walden-pond.html",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "50.853466984537700",
   "Longitute 1": "0.557679684632931",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Wild Peak",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.thissiteisunderconstruction.org/wild-peak-housing-co-op.html",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "53.039493902780300",
   "Longitute 1": "-1.487856988509210",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Xanadu Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.facebook.com/Xanadu-Housing-Co-op-109845313992724/",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "53.816789191768000",
   "Longitute 1": "-1.557927215257720",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Yellow Brick Road Housing Coop",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalroutes.org.uk/list-of-members/housing-co-ops/257-yellow-brick-road.html",
   "Description": "Housing co-op; member of Radical Routes",
   "Latitude 1": "51.526731453745000",
   "Longitute 1": "-0.661529230672862",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "The Burrow",
   "Type": "Housing",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://theburrowcoop.com/",
   "Description": "Queer-centred housing co-op; member of Radical Routes",
   "Latitude 1": "53.472689424592300",
   "Longitute 1": "-2.158938372941110",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Sumac Centre",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://sumac.org.uk/",
   "Description": "Activist and community resource centre and a secondary co-operative with two housing and three worker co-ops as members; member of Radical Routes",
   "Latitude 1": "52.971786217128500",
   "Longitute 1": "-1.163175423296030",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "The Cowley Club",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://cowley.club/",
   "Description": "Collectively owned and run social centre that houses a cafe and bookshop during the day, a members bar during the evenings, has a library and is a base for a variety of other projects.",
   "Latitude 1": "50.830736496572200",
   "Longitute 1": "-0.136259630681586",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Partisan",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://partisancollective.net/",
   "Description": "Partisan is a cooperative space for independent, community-led DIY and cultural projects.",
   "Latitude 1": "53.481599981783500",
   "Longitute 1": "-2.264781469318410",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Birmingham Bike Foundry",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://birminghambikefoundry.org/",
   "Description": "A worker co-operative bike repair shop; member of Radical Routes",
   "Latitude 1": "52.424457452841400",
   "Longitute 1": "-1.921602153977610",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Catalyst Collective Ltd",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.catalystcollective.org/",
   "Description": "Catalyst Collective is a worker’s co-operative supporting other co-ops and collectives; member of Radical Routes",
   "Latitude 1": "53.748527888771000",
   "Longitute 1": "-2.011713286425080",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Footprint Workers Cooperative",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalroutes.org.uk/list-of-members/trading-co-ops/footprint.html",
   "Description": "Printing cooperative; mostly prints activist material",
   "Latitude 1": "53.820010342628000",
   "Longitute 1": "-1.533096661363170",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Secure Active Coop",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://secure.coop/",
   "Description": "Information Security for the Non-Profit Sector by the Sector",
   "Latitude 1": "51.546015245054000",
   "Longitute 1": "-0.073408728836969",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Knott Wood Coppicers",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalroutes.org.uk/list-of-members/trading-co-ops/knott-wood-coppicers.html",
   "Description": "Workers coop that manages woodland and produces wood products",
   "Latitude 1": "53.745847695246000",
   "Longitute 1": "-2.011616530601180",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Firestorm Books & Coffee Coop",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://firestorm.coop/",
   "Description": "A collectively-owned radical bookstore and community event space since 2008.",
   "Latitude 1": "35.579341736532200",
   "Longitute 1": "-82.586505532272000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bound Together",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://boundtogetherbookssf.github.io/",
   "Description": "Anarchist collective-run bookstore featuring radical literature and events (since 1976)",
   "Latitude 1": "37.770420481859100",
   "Longitute 1": "-122.444251476327000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kasa Invisível",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://kasainvisivel.org/",
   "Description": "A building occupied by an autonomous and horizontal anti-capitalist collective.",
   "Latitude 1": "-19.924319493911700",
   "Longitute 1": "-43.942973940390000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Rubicund Bookshop",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.rubicund.co.uk/",
   "Description": "Rubicund is a radical bookshop, vegan cafe and lending library.",
   "Latitude 1": "50.154897806850000",
   "Longitute 1": "-5.068148258662010",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Esta es una Plaza",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://estaesunaplaza.blogspot.com/",
   "Description": "Self-organized square with some agriculture",
   "Latitude 1": "40.407761457968500",
   "Longitute 1": "-3.698371999999990",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "CSA La Tabacalera",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://latabacalera.net/",
   "Description": "Self-organized social center in a former tobacco factory",
   "Latitude 1": "40.406466907912600",
   "Longitute 1": "-3.703187737150320",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Centro Social 3 Peces 3",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://3peces3.wordpress.com/",
   "Description": "Self-organized social center where many collectives have their basis",
   "Latitude 1": "40.410910246796400",
   "Longitute 1": "-3.700102529479780",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kafé 44",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://kafe44.org/",
   "Description": "Kafé 44 is a café, concert venue, and anarchist bookstore in Stockholm",
   "Latitude 1": "59.316316345298000",
   "Longitute 1": "18.084739459791900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Ungdomshuset",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.ungeren.dk/ / http://dortheavej-61.dk/",
   "Description": "Meeting space, event space, kitchen and also an infocafé hosting lectures etc",
   "Latitude 1": "55.709069865471200",
   "Longitute 1": "12.524869821695900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Tenacious Unicorn Ranch",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.tenaciousunicornranch.com/",
   "Description": "An alpaca farm which acts as a trans and queer haven in rural Westcliffe, Colorado.",
   "Latitude 1": "40.724423094251100",
   "Longitute 1": "-105.376430748610000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Idyll Dandy Arts (IDA)",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.idylldandyarts.com/",
   "Description": "A rural community land project and educational safer-space for artistic, queer, trans, and gender non-conforming people of color and others.",
   "Latitude 1": "35.933789887424100",
   "Longitute 1": "-85.896009535753900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Blitz House",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.blitz.no/",
   "Description": "A self-managed social centre with feminist radio station, vegan café, concert hall, practice rooms, bookshop and infoshop.",
   "Latitude 1": "59.918851911140300",
   "Longitute 1": "10.738614944975900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Faslane Peace Camp",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://faslanepeacecamp.wordpress.com/",
   "Description": "A permanent peace camp sited alongside Faslane Naval base in Argyll and Bute, Scotland",
   "Latitude 1": "56.046057894220400",
   "Longitute 1": "-4.805548240255400",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "AKAB Okretnica",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://okretnica.noblogs.org/",
   "Description": "An anti-authoritarian social centre with anti-fascist events group, a screen printing studio, a zine distro, growing library and youth group.",
   "Latitude 1": "44.819829890391600",
   "Longitute 1": "20.489954375717300",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Dial House",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.radicalessex.uk/dial-house/",
   "Description": "Anarcho-pacifist community in a 16th century cottage squatted in 1967",
   "Latitude 1": "51.712658431088000",
   "Longitute 1": "0.188846744750046",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "די ראָזעווע פּאַווע pink peacock",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://pinkpeacock.gay/",
   "Description": "a queer yiddish anarchist pay-what-you-can café",
   "Latitude 1": "55.835707053579000",
   "Longitute 1": "-4.264917847885130",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Wooden Shoe",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.woodenshoebooks.org/home.html",
   "Description": "Anarchist & radical bookstore ",
   "Latitude 1": "39.942245793869800",
   "Longitute 1": "-75.154151073162900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Bluestockings Cooperative Bookstore",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://bluestockings.com/",
   "Description": "Bluestockings Cooperative is a worker-owned community space and bookstore guided by the principles of abolition feminism, solidarity, and transformative justice practices.",
   "Latitude 1": "40.719127637123500",
   "Longitute 1": "-73.985918401976000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Museum of Reclaimed Urban Space",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "As a living history of urban activism, the Museum of Reclaimed Urban Space (MoRUS) chronicles the East Village community’s history of grassroots action.",
   "Latitude 1": "40.725896171231800",
   "Longitute 1": "-73.977924744303600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "11th Street Community Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open M-F: 10a-11p; Sa-Su: 11a-5p",
   "Latitude 1": "40.729542138951400",
   "Longitute 1": "-73.983148219935300",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Dias y Flores",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Sa-Su: 12a-5p",
   "Latitude 1": "40.729144477694300",
   "Longitute 1": "-73.979890351371400",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "El Sol Brillante Jr.",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open M-W: 10a-11p; Sa-Su: 11a-5p",
   "Latitude 1": "40.728672251865300",
   "Longitute 1": "-73.979463986022400",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Vamos Sembrar",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open May-Nov M-F 7a-3p",
   "Latitude 1": "40.728315292231700",
   "Longitute 1": "-73.979007725857000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Yu Swen Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open F: 4p-8p, Sa-Su: 12p-5p",
   "Latitude 1": "40.728602597580700",
   "Longitute 1": "-73.978430033741800",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "El Sol Brillante Sr.",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Daily as used by members",
   "Latitude 1": "40.728335519856400",
   "Longitute 1": "-73.980054285399300",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Children's Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Su: 9a-2p",
   "Latitude 1": "40.728156649971700",
   "Longitute 1": "-73.979453470586800",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "East 11th Street Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden",
   "Latitude 1": "40.727408643461500",
   "Longitute 1": "-73.979056503658500",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Campos Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open M & F: 9a-12p; W: 9a-1p",
   "Latitude 1": "40.727302946213100",
   "Longitute 1": "-73.977554466634000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "11 BC Serenity Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open May-Oct T & Th 9a-5p, Su: 1p-5p",
   "Latitude 1": "40.726862512256500",
   "Longitute 1": "-73.978441999526400",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "9th Street Community Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden Sa-Su: 12a-6p",
   "Latitude 1": "40.725368489915000",
   "Longitute 1": "-73.977763406557400",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Plaza Cultural",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open April-May Sa-Su: 11a-5p; June-Aug T-Th 6-8p Sa-Su: 3p-7p; Sept-Oct: Sa-Su: 10a-6p",
   "Latitude 1": "40.725279822828200",
   "Longitute 1": "-73.978266501354200",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "De Colores Community Yard",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Sa-Su: 11a-5p",
   "Latitude 1": "40.725395089993000",
   "Longitute 1": "-73.979781635651300",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Casita Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden ",
   "Latitude 1": "40.725228080971400",
   "Longitute 1": "-73.979367240707500",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Earth People",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Sa: 12p-5p",
   "Latitude 1": "40.725121139858100",
   "Longitute 1": "-73.978893732867600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Flower Door Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open M-F: 11a-12p; S: 10a-2p",
   "Latitude 1": "40.724919139496800",
   "Longitute 1": "-73.978548793370600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Fireman's Memorial Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open M-F: 12-5p",
   "Latitude 1": "40.724412947681600",
   "Longitute 1": "-73.977915359028300",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Sam & Sadie Koenig Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Sa-Su: 11a-4p",
   "Latitude 1": "40.724044589721300",
   "Longitute 1": "-73.978385731055300",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Green Oasis & Gilbert's Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open May-Oct: Sa-Su: 11a-5p",
   "Latitude 1": "40.724127767503100",
   "Longitute 1": "-73.977307011207900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Lower East Side Ecology Center",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Su: 8a-5p",
   "Latitude 1": "40.724567419769600",
   "Longitute 1": "-73.979740402507500",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Creative Little Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Daily: 11a-6p",
   "Latitude 1": "40.724767044706000",
   "Longitute 1": "-73.982525004937300",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "6th Street & Ave B Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden Sa-Su: 1a-6p",
   "Latitude 1": "40.724467607081200",
   "Longitute 1": "-73.981844533405600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "6BC Botanical Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open M-F: 6p-8p; Sa-Su: 12p-4p",
   "Latitude 1": "40.723940023213900",
   "Longitute 1": "-73.980587072178100",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "6th Street Community Center",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden",
   "Latitude 1": "40.723768914033700",
   "Longitute 1": "-73.980220181995100",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "5th Street Slope Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden",
   "Latitude 1": "40.723569986344800",
   "Longitute 1": "-73.980902274093100",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Secret Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Sa: 12p-Dusk",
   "Latitude 1": "40.722419448595200",
   "Longitute 1": "-73.980261499096800",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "El Jardin Del Paraiso",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Sa-Su: 12p-5p",
   "Latitude 1": "40.722481071231800",
   "Longitute 1": "-73.979090644149500",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Generation X Cultural Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Sa-Su: 8a-8p",
   "Latitude 1": "40.722660499308700",
   "Longitute 1": "-73.981320913360000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Los Amigos Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open M-F: 11a-4p; Sa 11a-6p",
   "Latitude 1": "40.722419815036900",
   "Longitute 1": "-73.982089984536800",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Brisas Del Caribe",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open M-F: 11a-2pm",
   "Latitude 1": "40.722126932993000",
   "Longitute 1": "-73.981286477340700",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Parque de Tranquilidad",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open M-Su: 9a-7p",
   "Latitude 1": "40.721926844514100",
   "Longitute 1": "-73.979572328658600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "All People's Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open M-F: 11a-2pm",
   "Latitude 1": "40.721355574118900",
   "Longitute 1": "-73.979648853150700",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Ochard Alley",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Su: 10a-8p",
   "Latitude 1": "40.721457069216300",
   "Longitute 1": "-73.978351762976700",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Miracle Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Sa-Su: 12-7p",
   "Latitude 1": "40.722807828017000",
   "Longitute 1": "-73.983490999251100",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Kenkeleba House Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Th-F: 10:30a-6:30p",
   "Latitude 1": "40.722460479628600",
   "Longitute 1": "-73.982600553186200",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Peach Tree Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Sa-Su: 1-6p",
   "Latitude 1": "40.721477970076500",
   "Longitute 1": "-73.981867244662000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Hope Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden open Sa-Su: 11a-6p",
   "Latitude 1": "40.722113129434300",
   "Longitute 1": "-73.984106454645800",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Le Petit Varsailles Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.morusnyc.org/",
   "Description": "Squated Communty Garden",
   "Latitude 1": "40.721219934868800",
   "Longitute 1": "-73.981736296716000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Vidalia",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://vidalia.coop/home/",
   "Description": "Intentional community in an old textile mill",
   "Latitude 1": "41.944905800750800",
   "Longitute 1": "1.881090500000890",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Free and Real",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://en.freeandreal.org/",
   "Description": "Experimental eco-housing community",
   "Latitude 1": "38.929279060398400",
   "Longitute 1": "23.040340345723600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Stagones",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "www.stagones.org",
   "Description": "Democratic eco-farming cooperative",
   "Latitude 1": "38.033975942113700",
   "Longitute 1": "23.756430355457600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Keveral Farm",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "http://www.keveral.org/",
   "Description": "Collectively owned organic farm",
   "Latitude 1": "50.371970031380700",
   "Longitute 1": "-4.394562668668280",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Canon Frome Court",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.canonfromecourt.org.uk/",
   "Description": "Farming co-operative and intentional community",
   "Latitude 1": "52.089158002692200",
   "Longitute 1": "-2.519605215749940",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Landmatters",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.landmatterscoop.org/",
   "Description": "Co-operatively run permaculture project",
   "Latitude 1": "50.828404087240700",
   "Longitute 1": "-0.141633421687900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Braziers Park",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.braziers.org.uk/",
   "Description": "Collectively run intentional community and school that started in 1950",
   "Latitude 1": "51.555157535366000",
   "Longitute 1": "-1.084137836401270",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Village Emmaüs Lescar Pau",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.emmaus-lescar-pau.net",
   "Description": "Village project started in 1974; 120 inhabitants running many aterliers and farmland",
   "Latitude 1": "43.343594467528400",
   "Longitute 1": "-0.416033715698405",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Clémenterie",
   "Type": "Land / Food",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://laclementerie.wordpress.com/",
   "Description": "Self-organized farm. Focus on education. Feminist, anarchist, etc.",
   "Latitude 1": "44.630405975573400",
   "Longitute 1": "4.161241863130940",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Bolina",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.labolina.org/",
   "Description": "Ecological project that integrates refugees and fights inequality",
   "Latitude 1": "36.930479123880500",
   "Longitute 1": "-3.583151015785760",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Well Space Community",
   "Type": "Projects",
   "Last Confirmed Date": "1/23/2022",
   "Website": "https://www.wellspacecommunity.co.uk/ourstory",
   "Description": "\"Free store\" based on the idea that you take whatever you can use and bring whatever you can give. Fridays Noon - 5pm.",
   "Latitude 1": "51.544892617061500",
   "Longitute 1": "-0.046660777253820",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Time's Up",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://times-up.org",
   "Description": "Volunteer based direction action environmental organization. Community gardens, renewable energy, etc. Affordable recycled bikes. Open bike repair workshops Wednesdays & Sundays",
   "Latitude 1": "40.710903378636600",
   "Longitute 1": "-73.964569373194400",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "The Base",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://thebasebk.org",
   "Description": "Sociopolitical space committed to the dissemination of radical-left ideas and organizing, as well as thje establishment of frameworks encouraging creative modes of sharing. Political theory workshops, reading groups, film screenings, free spanish lessons.",
   "Latitude 1": "40.698046179333400",
   "Longitute 1": "-73.924457730812700",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Recycle a Bicycle",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "",
   "Description": "Community based bike shop and non-profit organization. Job training, environmental education, youth programs, bicycle advocacy.",
   "Latitude 1": "40.683356843404600",
   "Longitute 1": "-73.967060373141000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "The Brooklyn Free Store",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://facebook.com/bkfreestrore",
   "Description": "\"Free store\" based on the idea that you take whatever you can use and bring whatever you can give.",
   "Latitude 1": "40.690210834176300",
   "Longitute 1": "-73.948219581287900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Word Up Community Bookshop",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "wordupbooks.com",
   "Description": "All volunteer radical bookstore, fair trade cafe and activist center. ",
   "Latitude 1": "40.837593276891000",
   "Longitute 1": "-73.938223215464300",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Revolution Books",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://revolutionbooksnyc.org",
   "Description": "A bookstore at the center of a movement for revolution. Literature, history, philosophjy, and revolutionary theory.",
   "Latitude 1": "40.812146939893400",
   "Longitute 1": "-73.942562428956700",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "NYC Books Through Bars",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://booksthroughbarsnyc.org",
   "Description": "All-volunteer group of activists that organize packing sessions and send books to prisons.",
   "Latitude 1": "40.687384866718000",
   "Longitute 1": "-74.001351130813000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Every Thing Goes",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://ganas.org",
   "Description": "Bookstore and cafe. Neighborhood stage with shows every weekend. Thrift store with furniture and clothing.",
   "Latitude 1": "40.636906696145900",
   "Longitute 1": "-74.076666157798100",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "ABC No Rio",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://abcnorio.org",
   "Description": "Collectively run center for art and activism, internationally known venue for oppositional culture, and place where people share resources and ideas to impact society, culture and community.",
   "Latitude 1": "40.719280221622900",
   "Longitute 1": "-73.985363273139900",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Autonomedia",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://antonomedia.org",
   "Description": "Anti-profit radical media collective and autonomous zone for arts, publisher of books on radical media, politics, and arts.",
   "Latitude 1": "40.707715148659800",
   "Longitute 1": "-73.966781188484500",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Interference Archive",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://interferencearchive.org",
   "Description": "All volunteer archive and collectively run space exploring the relationship between cultural production and social movements.",
   "Latitude 1": "40.670414716847300",
   "Longitute 1": "-73.985795186633100",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "16 Beaver Group",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://16beavergroup.org",
   "Description": "Independent collectively-sustaining space run by artists. Platofmr for presentation, production, and discussion of artistic cultural, economic, political projects.",
   "Latitude 1": "40.704761412758100",
   "Longitute 1": "-74.012533530812400",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "The People's Forum",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://peoplesforum.org",
   "Description": "Movement incubator for working class and marginalized communities to build unity across historic lines of division at home and abroad.",
   "Latitude 1": "40.754432097586000",
   "Longitute 1": "-73.993277530811000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Earth Arts Center",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://earthartscenter.com",
   "Description": "The Earth Arts Center brings environmental education to community through creative participation. Environmental advocacy programs, educational opportunities, wellness projects, services.",
   "Latitude 1": "40.688075785403100",
   "Longitute 1": "-73.920084946157300",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Food Not Bombs",
   "Type": "Land / Food",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://foodnotbombs.net",
   "Description": "A worldwide movement made up of autonomous groups who believe that food and other necessities of life are rights not privileges. Vegetarian meal share using donated food that would otherwise be wasted. Free store and free meals at 3pm every Saturday. (See website for more sites.)",
   "Latitude 1": "40.726104251706000",
   "Longitute 1": "-73.981730457795500",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Aj Muste Institute",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://ajmuste.org/collaborative-workspace",
   "Description": "AKA Peace Pentagon. Collaborative activist center.",
   "Latitude 1": "40.716295951704700",
   "Longitute 1": "-73.997268800123600",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "8 Ball Community",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://8ballcommunity.club",
   "Description": "Our mission is to generate collaborative exchange through public access television and radio stations.",
   "Latitude 1": "40.651359079338500",
   "Longitute 1": "-73.977788532666700",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "La Plaza Community Garden",
   "Type": "Land / Food",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://laplazacultural.com",
   "Description": "Event-based garden, practicing and teaching many sustainable systems: water recovery, French drains, permaculture, solar energy, composting workshops.",
   "Latitude 1": "40.641238474003200",
   "Longitute 1": "-73.970232915470100",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Friends of Brook Park",
   "Type": "Land / Food",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://instagram.com/friendsofbrookpark",
   "Description": "Events, compost drop-off, bike tours, green house, chicken coop, arts and music, canoe excursions, organic farming.",
   "Latitude 1": "40.809538747255500",
   "Longitute 1": "-73.918303953262200",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "Tehuti Ma'at",
   "Type": "Projects",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://tehutimaat.org",
   "Description": "Serves as a resource to foster political, social, and economic empowering knowledge and skills to people of African ascent, in order to co-create hope and unity.",
   "Latitude 1": "40.673533879336000",
   "Longitute 1": "-73.921942561502000",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "East Village Community Fridge",
   "Type": "Land / Food",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://instagram.com/eastvillageneighbors",
   "Description": "Community Fridge",
   "Latitude 1": "40.730171260668300",
   "Longitute 1": "-73.983665322138800",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "466 Grand Community Fridge",
   "Type": "Land / Food",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://instagram.com/les_communityfridge",
   "Description": "Community Fridge - fresh produce only",
   "Latitude 1": "40.715278983956900",
   "Longitute 1": "-73.983857065910800",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 },
 {
   "Name": "6th Street Community Fridge",
   "Type": "Land / Food",
   "Last Confirmed Date": "10/8/2021",
   "Website": "https://instagram.com/sixthstreetcc",
   "Description": "Community Fridge open 11 am to 4pm",
   "Latitude 1": "40.723872982739900",
   "Longitute 1": "-73.980391445218100",
   "Latitude 2": "",
   "Longitute 2": "",
   "Latitude 3": "",
   "Longitute 3": "",
   "Latitude 4": "",
   "Longitute 4": "",
   "Latitude 5": "",
   "Longitute 5": "",
   "Latitude 6": "",
   "Longitute 6": "",
   "Latitude 7": "",
   "Longitute 7": "",
   "Latitude 8": "",
   "Longitute 8": "",
   "Latitude 9": "",
   "Longitute 9": "",
   "Latitude 10": "",
   "Longitute 10": "",
   "Latitude 11": "",
   "Longitute 11": "",
   "Latitude 12": "",
   "Longitute 12": "",
   "Latitude 13": "",
   "Longitute 13": "",
   "Latitude 14": "",
   "Longitute 14": "",
   "Latitude 15": "",
   "Longitute 15": "",
   "Latitude 16": "",
   "Longitute 16": "",
   "Latitude 17": "",
   "Longitute 17": "",
   "Latitude 18": "",
   "Longitute 18": "",
   "Latitude 19": "",
   "Longitute 19": "",
   "Latitude 20": "",
   "Longitute 20": ""
 }
];//Last Download: Jan 23, 2022
export default freeingSpacesFromCSV;
